import React from "react";

const CalandarLegend = () => (
  <div className="calendar-legend">
    <span className="color-block completed" />
    <span className="legend-text">Completed</span>
    <span className="color-block expired" />
    <span className="legend-text">Expired</span>
    <span className="color-block un-confirmed" />
    <span className="legend-text">Confirmation Pending</span>
    <span className="color-block confirmed" />
    <span className="legend-text">Confirmed</span>
    <span className="color-block timeoff" />
    <span className="legend-text">TimeOff/LunchTime</span>
  </div>
);

export default CalandarLegend;
