import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

export const SelectRowContext = createContext();

export const RowSelectCell = React.memo(({ id, tdStyle, labelStyle }) => {
  const { isRowSelected, toggleRow } = useContext(SelectRowContext);
  return (
    <td
      style={
        {
          padding: 0,
          ...(tdStyle ? tdStyle : {})
        }
      }
    >
      <label
        style={
          {
            width: "100%",
            height: 34,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            cursor: "pointer",
            ...(labelStyle ? labelStyle : {})
          }
        }
      >
        <input
          checked={isRowSelected(id)}
          name={id}
          onChange={toggleRow}
          type="checkbox"
          style={{ padding: 0, margin: 0, cursor: "pointer" }}
        />
      </label>
    </td>
  );
});

RowSelectCell.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tdStyle: PropTypes.object,
  labelStyle: PropTypes.object
};

export const RowSelectHeaderCell = React.memo(({ text, customBlueHeader = false }) => {
  const { toggleAllRows } = useContext(SelectRowContext);
  return (
    <td
      className={`query-table-header-cell-container ${customBlueHeader && "custom-blue-header"}`}
      style={{ minWidth: 20, width: 20 }}
    >
      <div
        className="query-table-header-cell"
        onClick={toggleAllRows}
        style={{ width: 15 }}
      >
        <div className="header-text" style={{ width: 15, paddingLeft: 3 }}>
          {text ? text : "All"}
        </div>
      </div>
    </td>
  );

});
RowSelectHeaderCell.propTypes = {
  text: PropTypes.string,
  customBlueHeader: PropTypes.bool
}

