import { get, put } from "utils/api";
import { errorMessage } from "actions/message";

export const Types = {
  REQUEST_USER_PROFILE: "REQUEST_USER_PROFILE",
  USER_PROFILE_RESPONSE: "USER_PROFILE_RESPONSE",
  USER_PROFILE_ERROR: "USER_PROFILE_ERROR",
  REQUEST_UPDATE_USER_PROFILE: "REQUEST_UPDATE_USER_PROFILE",
  UPDATE_USER_PROFILE_RESPONSE: "UPDATE_USER_PROFILE_RESPONSE",
  UPDATE_USER_PROFILE_ERROR: "UPDATE_USER_PROFILE_ERROR",
  REQUEST_USER_INSIGHTS: "REQUEST_USER_INSIGHTS",
  USER_INSIGHTS_RESPONSE: "USER_INSIGHTS_RESPONSE",
  USER_INSIGHTS_ERROR: "USER_INSIGHTS_ERROR",

  REQUEST_UPDATE_COACH_SETTINGS: "REQUEST_UPDATE_COACH_SETTINGS",
  UPDATED_COACH_SETTINGS: "UPDATED_COACH_SETTINGS",
  UPDATE_COACH_SETTINGS_FAILED: "UPDATE_COACH_SETTINGS_FAILED",
  SET_USER_SETTINGS: "SET_USER_SETTINGS",
  USER_SETTINGS_UPDATE_EVENT: "USER_SETTINGS_UPDATE_EVENT",
  SET_USER_SCHEDULER_DATA: "SET_USER_SCHEDULER_DATA",
  REQUEST_EXPORT_USER_INSIGHTS: "REQUEST_EXPORT_USER_INSIGHTS",
  RESPONSE_EXPORT_USER_INSIGHTS: "RESPONSE_EXPORT_USER_INSIGHTS",
};

export const fetchUserProfile = id => async dispatch => {
  dispatch({ type: Types.REQUEST_USER_PROFILE });
  try {
    const response = await get(`users/${id}`);
    dispatch({ type: Types.USER_PROFILE_RESPONSE, payload: response });
    return response;
  } catch (error) {
    /* NOTE: invalid guid returns valid request but empty response */
    dispatch({ type: Types.USER_PROFILE_ERROR, error });
  }
};

export const fetchUserInsights = (
  userId,
  start_date,
  end_date
) => async dispatch => {
  try {
    dispatch({ type: Types.REQUEST_USER_INSIGHTS });
    const response = await get(
      `insights?id=${userId}&start_date=${start_date}&end_date=${end_date}`
    );
    dispatch({
      type: Types.USER_INSIGHTS_RESPONSE,
      payload: response.insights || response
    });
    return response;
  } catch (error) {
    dispatch({ type: Types.USER_INSIGHTS_ERROR, error });
  }
};

export const updateUserProfile = (id, user) => async dispatch => {
  dispatch({ type: Types.REQUEST_UPDATE_USER_PROFILE });
  try {
    const response = await put(`users/${id}`, user);
    dispatch({ type: Types.UPDATE_USER_PROFILE_RESPONSE, payload: response });
    return response;
  } catch (error) {
    dispatch({ type: Types.USER_PROFILE_ERROR, error });
  }
};

/* emits signalr event to coach */
export const updateCoachSettings = (userId, updates) => async dispatch => {
  try {
    await put(`users/${userId}`, updates);
    dispatch({
      type: Types.UPDATED_COACH_SETTINGS,
      payload: updates,
      meta: { userId }
    });
  } catch (error) {
    dispatch(errorMessage("Sorry something went wrong."));
    dispatch({ type: Types.UPDATE_COACH_SETTINGS_FAILED, error });
  }
};

export const exportUserInsights = (payload) => ({
  type: Types.REQUEST_EXPORT_USER_INSIGHTS,
  payload,
})