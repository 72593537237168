import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AvaiableDaysRow } from "./AvailableDaysRow";

export const AvailableDays = ({
  appointment_config,
  companyAllowsWeekends,
  errors,
  onLunchStartChanged
}) => {
  const [daysToRender, setDaysToRender] = useState({});

  let { days, allow_weekend_appointment } = appointment_config;

  useEffect(() => {
    if (!allow_weekend_appointment) {
      const { ...rest } = days;
      setDaysToRender(rest);
    } else {
      setDaysToRender(days);
    }
  }, [companyAllowsWeekends]);

  return (
    <div>
      <legend>Available days</legend>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          gap: "5.8em",
          width: "460px"
        }}
      >
        <p style={{ margin: "0 0 0 110px" }}>Begin</p>
        <p style={{ margin: "0" }}>End</p>
        <p style={{ margin: "0" }}>Lunch Time</p>
      </div>

      {Object.keys(daysToRender).map(dayKey => (
        <AvaiableDaysRow
          key={dayKey}
          daySchedule={days[dayKey]}
          errors={errors}
          onLunchStartChanged={value => onLunchStartChanged(value, dayKey)}
        />
      ))}
    </div>
  );
};
AvailableDays.propTypes = {
  appointment_config: PropTypes.object,
  companyAllowsWeekends: PropTypes.bool,
  errors: PropTypes.object,
  onLunchStartChanged: PropTypes.func
};
