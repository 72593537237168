import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  TimeSlotContext
} from "../../availability-scheduler/use-availabilityScheduler";

import Header from "./header";
import ViewByWeek from "./view-by-week";
import ViewByDay from "./view-by-day";
import ViewByMonth from "./view-by-month";

export default function CoachView() {

  const {
    coachViewView,
    coachViewCoachID,
    setCoachViewCoachID,
    managerViewCoaches,
    gettingCoachViewAppointmentsForMonth,
    gettingCoachViewAppointments
  } = useContext(TimeSlotContext);

  return (
    <div className={"calendar-wrapper-coach"}>
      <Header />
      <select
        style={{ width: "300px", margin: "0 auto", marginTop: "10px" }}
        onChange={({ target }) => setCoachViewCoachID(target.value)}
        value={coachViewCoachID}
        disabled={gettingCoachViewAppointmentsForMonth || gettingCoachViewAppointments}
      >
        {managerViewCoaches?.map(coach => <option key={coach.id} value={coach.id}>{coach.name}</option>)}
      </select>
      {coachViewView == "day" && <ViewByDay />}
      {coachViewView == "week" && <ViewByWeek />}
      {coachViewView == "month" && <ViewByMonth />}
    </div>
  );
}

CoachView.propTypes = {
  timeZone: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  resetTimeZoneValue: PropTypes.func
};
