import { put } from "utils/api";

export const fetchCoachAvailability = ({ selectedDate, appointment_topic_id, coachId, patientId }) =>
  async () => {
    try {
      const payload = {
        date: selectedDate,
        appointment_topic_id,
        coachId,
        patientId
      };

      const result = await put(
        "patient_portal/mobile/coach_availability_slots",
        payload
      );
      return result;
    } catch (error) {
      throw Error(error.message);
    }
  };
