import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";

const FormikField = ({
  name,
  label,
  labelAppend,
  style,
  className,
  inlineError,
  requiredQuestion,
  customHandleErrors = false,
  ...rest
}) => (
  <span className={className} style={style}>
    <span className="form-field-header">
      {label && (
        <label className="form-field-label" style={requiredQuestion ? {marginRight: "0"} : undefined} htmlFor={name}>
          {label}
        </label>
      )}
      {requiredQuestion && (
        <label style={{color: "red"}} htmlFor={name}>
            *
        </label>
      )}
      {labelAppend && (
        <label className="form-field-label-append" htmlFor={labelAppend}>
          {`* ${labelAppend}`}
        </label>
      )}
    </span>
    
    {customHandleErrors ? (
      <Field name={name} {...rest} id={name} className="input-field" />
    ) : (
    <>
          {!inlineError && (
              <span className="form-field-error">
                  <ErrorMessage name={name} />
              </span>
          )}
          <Field name={name} {...rest} id={name} className="input-field" />
          {inlineError && (
              <span className="form-field-error-inline">
                  <ErrorMessage name={name} />
              </span>
          )}
      </>
  )}
    
  </span>
);
export default FormikField;

FormikField.propTypes = {
  name: PropTypes.string.isRequired,
  labelAppend: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.object,
  className: PropTypes.string,
  inlineError: PropTypes.bool,
  requiredQuestion: PropTypes.bool,
  customHandleErrors: PropTypes.bool,
};

FormikField.defaultProps = { style: {}, className: "form-field" };
