import React, { Component } from "react";
import MessageForm from "./../../../components/TwilioProgrammableTextChat/MessageForm";
import MessageList from "./../../../components/TwilioProgrammableTextChat/MessageList";
import TwilioChat from "twilio-chat";
import "./style.css";
import {
  getChatDetails,
  setAPIError,
  setTwilioError,
  setTwilioConversationSummary
} from "./../../../Redux/Actions/chat";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Spinner, Waiting } from "components/ui";
import { selectors } from "reducers";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowedChat: false,
      APIMembers: [],
      chatInitiate: false,
      messages: [],
      username: null,
      channel: null,
      identity: "",
      jsonobject: {},
      participantName: "",
      participantSid: "",
      host: {},
      allowLeaveChat: false,
      showSpinner: false
    };
  }

  static propTypes = {
    getChatDetails: PropTypes.func.isRequired,
    setAPIError: PropTypes.func.isRequired,
    setTwilioConversationSummary: PropTypes.func.isRequired,
    RoomDetails: PropTypes.object.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired
      })
    }),
    setTwilioError: PropTypes.func.isRequired,
    isLoader: PropTypes.bool,
    twilioErrorMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    userId: PropTypes.string,
    LeaveChatSuccess: PropTypes.bool
  };

  componentDidMount() {
    let authDetails =
      JSON.parse(window.localStorage.getItem("evergreen")) &&
      JSON.parse(window.localStorage.getItem("evergreen"));
    if (authDetails && authDetails.twilio) {
      this.setState({
        identity: authDetails.first_name + " " + authDetails.last_name,
        twilioToken: authDetails.twilio.twilioToken
      });
    }

    let slot_id = "";
    if (this.props.match.params.id) {
      slot_id = this.props.match.params.id;
      this.props.setAPIError("");
      this.props.setTwilioError("");
      this.props.getChatDetails(slot_id, 1);
    } else {
      this.props.setAPIError("Facing issue to fetch details.");
    }
    if(this.state.APIMembers && this.state.APIMembers.length > 0){
        this.setState({
            host: this.state.APIMembers.find(element => element.host == true)
        }) 
    }
  }

  static getDerivedStateFromProps(props) {
    if (
      props.RoomDetails &&
      props.RoomDetails.uniqueName &&
      props.RoomDetails.uniqueName.length > 0
    ) {
      return {
        uniqueName: props.RoomDetails.uniqueName,
        friendlyName: props.RoomDetails.friendlyName,
        APIMembers: props.RoomDetails.members,
        allowLeaveChat: props.LeaveChatSuccess
      };
    }

    return null;
  }

  componentDidUpdate() {
    if(this.state.allowLeaveChat)
      this.leaveChatRoom();

    if (
      !this.state.chatInitiate &&
      this.props.RoomDetails.friendlyName &&
      this.props.RoomDetails.uniqueName &&
      this.state.twilioToken
    ) {
      this.setState(
        {
          jsonobject: {
            identity: this.state.identity,
            token: this.state.twilioToken
          },
          host: this.state.APIMembers.find(element => element.host == true)
        },
        () => {
          this.initiateChat();
        }
      );
    }
  }

  initiateChat = () => {
    if (!this.state.chatInitiate) {
      this.getToken()
        .then(this.createChatClient)
        .then(this.joinChatChannel)
        .then(this.configureChannelEvents)
        .then(this.restoreMessages)
        .catch(error => {
          this.props.setTwilioError(error.message);
          this.addMessage({ body: error.message });
        });
    }
  };

  getToken = () => {
    return new Promise(resolve => {
      this.addMessage({
        body:
          "Please wait, connecting to room as " + this.state.identity + " ..."
      });
      this.setState({
        username: this.state.jsonobject.identity,
        chatInitiate: true
      });
      resolve(this.state.jsonobject);
    });
  };

  restoreMessages = async () => {
    try {
      if (this.channel) {
        const { items = [] } = await this.channel.getMessages();
        const restoredMessages = items.map(message => ({
          author: message.author,
          ...message.state
        }));
        this.setState({
          messages: [
            ...this.state.messages,
            ...restoredMessages.map(message => {
              if (message.author && message.author.length > 0) {
                const participantDetail =
                  this.state.APIMembers &&
                  this.state.APIMembers.length > 0 &&
                  this.state.APIMembers.filter(m => m.id === message.author) &&
                  this.state.APIMembers.filter(m => m.id === message.author)
                    .length > 0 &&
                  this.state.APIMembers.filter(m => m.id === message.author)[0];
                if (participantDetail) {
                  return { ...message, author: participantDetail.name, authorId: message.author };
                }
              }
              return message;
            })
          ]
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  createChatClient = () => {
    return new Promise((resolve, reject) => {
      TwilioChat.create(this.state.twilioToken)
        .then(client => {
          resolve(client);
        })
        .catch(error => reject(Error(error)));
    });
  };

  joinChatChannel = async chatClient => {
    try {
      const channel = await chatClient.getChannelByUniqueName(
        this.state.uniqueName
      );
      this.addMessage({ body: "Joining Chat Room..." });
      this.setState({ channel });
      if (channel.channelState.status !== "joined") {
        await channel.join();
      }
      this.addMessage({ body: "Joined Chat Room..." });
      this.setState({
        allowedChat: true
      });
      window.addEventListener("beforeunload", () => channel.leave());
      this.channel = channel;
      return channel;
    } catch (err) {
      console.error(err);
    }
  };

  addMessage = message => {
    if (message.author && message.author.length > 0) {
      const participantDetail =
        this.state.APIMembers &&
        this.state.APIMembers.length > 0 &&
        this.state.APIMembers.filter(m => m.id === message.author) &&
        this.state.APIMembers.filter(m => m.id === message.author).length > 0 &&
        this.state.APIMembers.filter(m => m.id === message.author)[0];
      if (participantDetail) {
        message.author = participantDetail.name;
      }
    }
    const messageData = { ...message };
    this.setState({
      messages: [...this.state.messages, messageData]
    });
  };

  handleNewMessage = text => {
    if (this.state.channel) {
      this.state.channel.sendMessage(text, {
        authorName: this.state.username,
        authorId: this.state.host.id
      });
    }
  };

  configureChannelEvents = channel => {
    channel.on("messageAdded", message => {
      this.addMessage({
        author: message.author,
        ...message.state
      });
    });

    channel.on("memberJoined", member => {
      let PatientName = "Patient";
      const participantDetail =
        this.state.APIMembers &&
        this.state.APIMembers.length > 0 &&
        this.state.APIMembers.filter(m => m.id === member.identity) &&
        this.state.APIMembers.filter(m => m.id === member.identity).length >
          0 &&
        this.state.APIMembers.filter(m => m.id === member.identity)[0];
      if (participantDetail) {
        PatientName = participantDetail.name;
      }
      this.addMessage({ body: PatientName + ` has joined the room.` });
    });

    channel.on("memberLeft", member => {
      let PatientName = "Patient";
      const participantDetail =
        this.state.APIMembers &&
        this.state.APIMembers.length > 0 &&
        this.state.APIMembers.filter(m => m.id === member.identity) &&
        this.state.APIMembers.filter(m => m.id === member.identity).length >
          0 &&
        this.state.APIMembers.filter(m => m.id === member.identity)[0];
      if (participantDetail) {
        PatientName = participantDetail.name;
      }
      this.addMessage({ body: PatientName + ` has left the room.` });
    });
  };

  leaveRoom = () => {
    this.setState({showSpinner: true})
    this.props.setTwilioConversationSummary(this.state.messages, this.props.match.params.id)       
  };

  leaveChatRoom = () => {
    return new Promise((resolve, reject) => {
      if (this.state.channel) {
        this.state.channel.removeAllListeners();
        this.state.channel
          .leave()
          .then(() => {
            location.replace("/chats/upcoming");
            resolve();
          })
          .catch(error => {
            this.setState({
              channel: null
            });
            reject(error);
          });
      } else {
        console.log("leaving chat channel but not currently in a channel.");
      }
    });
  };

  refreshPage = () => {
    location.reload();
  };

  render() {
    return (
      <React.Fragment>
        <div className="dd_row">
          {this.props.RoomDetails.TextChatInitialize &&
            this.state.allowedChat && (
              <button disabled={this.state.showSpinner} onClick={this.leaveRoom} style={{borderRadius: "15px"}}>Leave Room {this.state.showSpinner ? <Spinner/> : null}</button>
            )}
          {!this.props.isLoader &&
            this.props.twilioErrorMessage &&
            this.props.twilioErrorMessage.length > 0 && (
              <button onClick={this.refreshPage}>Re-Join</button>
            )}
          {this.props.isLoader && <Waiting />}
          {this.props.isLoader && <h2>Please wait to configured room..</h2>}
          {this.props.errorMessage && this.props.errorMessage.length > 0 && (
            <h2 style={{ color: "red" }}>{this.props.errorMessage}</h2>
          )}
          {this.props.RoomDetails.TextChatInitialize && (
            <div className="messageList-container">
              <div className="AppContainer-text">
                <MessageList messages={this.state.messages} host={this.state.host}/>
                {this.state.allowedChat && (
                  <MessageForm onMessageSend={this.handleNewMessage} />
                )}
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  RoomDetails: state.getIn(["newRootReducer"]).rdc_chat.TextChatRoomDetails,
  errorMessage: state.getIn(["newRootReducer"]).rdc_chat.errorMessage,
  twilioErrorMessage: state.getIn(["newRootReducer"]).rdc_chat
    .twilioErrorMessage,
  isLoader: state.getIn(["newRootReducer"]).rdc_loader.isLoader,
  userId: selectors.getUserId(state),
  LeaveChatSuccess: state.getIn(["newRootReducer"]).rdc_chat.allowLeaveChat,

});

export default connect(mapStateToProps, {
  getChatDetails,
  setAPIError,
  setTwilioError,
  setTwilioConversationSummary
})(withRouter(Index));
