import moment from "moment";

const TimeOffset = 24; // grid row 0 starts at 6am
const StartHour = 6; // ends at 8pm

export const convertDatesToGridSpan = (appointment)  => {
  const { start_time, end_time, ...rest } = appointment

  const start = moment(new Date(start_time));
  const end = moment(new Date(end_time));
  var duration = moment.duration(end.diff(start));
  const hours = start.hours() * 4;
  const minutes = Math.floor(start.minutes() / 15);

  return {
    row1: hours + minutes - TimeOffset,
    col: start.weekday(),
    row2: hours + minutes + Math.floor(duration.asMinutes() / 15) - TimeOffset,
    week: start.week(),
    start_time,
    end_time,
    ...rest
  };
};
export const convertDatesToGridSpanByDay = (appointment, date)  => {
  const { start_time, end_time, ...rest } = appointment

  const start = moment(new Date(start_time));
  const end = moment(new Date(end_time));
  var duration = moment.duration(end.diff(start));
  const hours = start.hours() * 4;
  const minutes = Math.floor(start.minutes() / 15);

  return {
    row1: hours + minutes - TimeOffset,
    col: date,
    row2: hours + minutes + Math.floor(duration.asMinutes() / 15) - TimeOffset,
    week: start.week(),
    start_time,
    end_time,
    ...rest
  };
};

export const managerViewConvertDatesToGridSpan = (coaches, { StartDate, EndDate, CoachId, ...rest }) => {
  const start = moment(new Date(StartDate));
  const end = moment(new Date(EndDate));
  var duration = moment.duration(end.diff(start));
  const hours = start.hours() * 4;
  const minutes = Math.floor(start.minutes() / 15);

  const test = {
    row1: hours + minutes - TimeOffset,
    col: coaches.findIndex(c => c.id == CoachId),
    row2: hours + minutes + Math.floor(duration.asMinutes() / 15) - TimeOffset,
    week: start.week(),
    ...rest
  };
  return test;
};

export const managerViewConvertGridSpanToDates1 = ({ row1, row2, date }) => {
  const first_day = date.hours(StartHour).minutes(0);
  const startRow = row1 <= row2 ? row1 : row2;
  const endRow = row2 >= row1 ? row2 : row1;
  const start_date = moment(first_day)
    .add(Math.floor(startRow / 4), "hours")
    .add((startRow % 4) * 15, "minutes");

  const end_date = moment(first_day)
    .add(Math.floor(endRow / 4), "hours")
    .add((endRow % 4) * 15, "minutes");

  return {
    start_time: start_date.format("L LT"),
    end_time: end_date.format("L LT"),
    StartDate: start_date.format("L LT"),
    EndDate: end_date.format("L LT")
  };
};

export const managerViewGridPosTodate = (row, date, timeZone) => {
  const first_day = moment().tz(timeZone).day(date.day()).hours(StartHour).minutes(0).week(date.week());
  return moment(first_day)
    .add(Math.floor(row / 4), "hours")
    .add((row % 4) * 15, "minutes");
};

export const gridPosTodate = (row, col, week, timeZone) => {
  const first_day = moment().tz(timeZone).day(0).hours(StartHour).minutes(0).week(week);
  return moment(first_day)
    .add(Math.floor(row / 4), "hours")
    .add((row % 4) * 15, "minutes")
    .add(col, "days");
};

export const gridPosToDateSingleDay = (row, selectedDate, timeZone) => {
  const date = moment(selectedDate, "YYYY-MM-DD")
  const first_day = moment().tz(timeZone).day(date.day()).hours(StartHour).minutes(0).week(date.week());

  const result = first_day
    .add(Math.floor(row / 4), "hours")
    .add((row % 4) * 15, "minutes");
  return result
};

export const convertGridSpanToDates = ({ row1, row2, col, week }) => {
  const first_day = moment().day(0).hours(StartHour).minutes(0).week(week);
  const startRow = row1 <= row2 ? row1 : row2;
  const endRow = row2 >= row1 ? row2 : row1;
  const start_date = moment(first_day)
    .add(Math.floor(startRow / 4), "hours")
    .add((startRow % 4) * 15, "minutes")
    .add(col, "days");
  const end_date = moment(first_day)
    .add(Math.floor(endRow / 4), "hours")
    .add((endRow % 4) * 15, "minutes")
    .add(col, "days");
  return {
    start_time: start_date.format("L LT"),
    end_time: end_date.format("L LT")
  };
};
export const convertGridSpanToDatesByDay = ({ row1, row2, day }) => {
  const first_day = moment(day).hours(StartHour).minutes(0);
  const startRow = row1 <= row2 ? row1 : row2;
  const endRow = row2 >= row1 ? row2 : row1;

  const start_date = moment(first_day)
    .add(Math.floor(startRow / 4), "hours")
    .add((startRow % 4) * 15, "minutes")
  const end_date = moment(first_day)
    .add(Math.floor(endRow / 4), "hours")
    .add((endRow % 4) * 15, "minutes")

  return {
    start_time: start_date.format("L LT"),
    end_time: end_date.format("L LT")
  };
};

export const formatTimeFromTimeSlot = timeSlot => {
  const offsetTime = timeSlot + TimeOffset - 4;
  const hour = Math.floor(offsetTime / 4);
  const minutes = (offsetTime % 4) * 15;
  if (typeof hour === "number") {
    return hour === 23
      ? `12:${minutes} AM`
      : hour < 11
        ? `${hour + 1}:${minutes > 0 ? minutes : "00"} AM`
        : `${(hour % 12) + 1}:${minutes > 0 ? minutes : "00"} PM`;
  }
  return hour;
};

export const getGridRowSpan = (row1, row2) => Math.abs(row1 - row2);

const isBetween = (a, b) => n => (a < n) & (n < b);

export const isInvalidSlot = (a, b) => {
  if (a.row1 <= b.row1 && a.row2 >= b.row2) {
    return true;
  } else if (
    isBetween(b.row1, b.row2)(a.row1) ||
    isBetween(b.row1, b.row2)(a.row2)
  ) {
    return true;
  }
  // else if (a.row1 === b.row2 || b.row1 === a.row2) {
  //     return true;
  //   }
  return false;
};

export const isBefore = day => moment(day).isBefore(moment(), "minutes");

export const isBeforeNow = (selected, timeZone) => selected.isBefore(moment().tz(timeZone));
