import React from 'react'
import { TimeSlotContext } from "../../../availability-scheduler/use-availabilityScheduler"
import moment from "moment";
import IoArrowLeftA from "react-icons/lib/io/arrow-left-a";
import IoArrowRightA from "react-icons/lib/io/arrow-right-a";
import { IconButton } from "components/ui";
import PropTypes from "prop-types"
import MonthYearSelector from '../view-by-month/month-year-selector';

const CoachViewDatesPicker = () => {
  const {
    selectWeek, week,
    selectDate, date,
    selectMonth, month,
    selectYear, year,
    coachViewView,
    setPendingTimeSlot,
    gettingCoachViewAppointments,
    gettingCoachViewAppointmentsForMonth
  } = React.useContext(TimeSlotContext);


  return (
    <DatesPicker>
      {coachViewView === "day" && <DatesPicker.ByDay day={moment(date)} selectDay={selectDate} isFetching={gettingCoachViewAppointments} />}
      {coachViewView === "week" && <DatesPicker.ByWeek week={week} selectWeek={selectWeek} setPendingTimeSlot={setPendingTimeSlot} isFetching={gettingCoachViewAppointments} />}
      {coachViewView === "month" && <DatesPicker.ByMonth month={month} setMonth={selectMonth} year={year} setYear={selectYear} isFetching={gettingCoachViewAppointmentsForMonth} />}
    </DatesPicker>
  );
};

export default CoachViewDatesPicker

const DatesPicker = ({ children }) => {
  return (
    <div className='week-select'>
      {children}
    </div>
  );
};

DatesPicker.ByDay = ({ day, selectDay, isFetching }) => {

  const handleDayChange = calc => {
    const newDay = calc === 'add' ? moment(day).add(1, 'days') : moment(day).subtract(1, 'days');
    selectDay(newDay);
  };

  return (
    <>
      <IconButton
        icon={<IoArrowLeftA />}
        title="Previous Day"
        onClick={() => handleDayChange('substract')}
        style={{ padding: 4 }}
        disabled={isFetching}
      />
      <span style={{ fontSize: 15 }}>
        {day && day.format("MMMM Do YYYY")}
      </span>
      <IconButton
        icon={<IoArrowRightA />}
        title="Next Day"
        onClick={() => handleDayChange('add')}
        style={{ padding: 4 }}
        disabled={isFetching}
      />
    </>
  )
};

DatesPicker.ByWeek = ({ week, selectWeek, setPendingTimeSlot, isFetching }) => {
  const handleWeekChange = w => {
    selectWeek(w);
    setPendingTimeSlot(null);
  };
  const from_date = moment().week(week).startOf("week");
  const to_date = moment().week(week).endOf("week");
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <IconButton
        icon={<IoArrowLeftA />}
        title="Previous Week"
        onClick={() => handleWeekChange(week - 1)}
        style={{ padding: 4 }}
        disabled={isFetching}
      />
      <span style={{ fontSize: 15, marginTop: "4px", width: "200px", textAlign: "center" }}>
        {from_date.format("L")} - {to_date.format("L")}
      </span>
      <IconButton
        icon={<IoArrowRightA />}
        title="Next Week"
        onClick={() => handleWeekChange(week + 1)}
        style={{ padding: 4 }}
        disabled={isFetching}
      />
    </div>
  );
};

DatesPicker.ByMonth = ({ month, setMonth, year, setYear, isFetching }) => {

  const handleDateChange = (newYear, newMonth) => {
    setYear(newYear);
    setMonth(newMonth);
  };

  return <MonthYearSelector
    selectedYear={year}
    selectedMonth={month}
    onDateChange={handleDateChange}
    disabled={isFetching}
  />;
};

DatesPicker.propTypes = {
  children: PropTypes.node.isRequired,
  isFetching: PropTypes.bool
};
DatesPicker.ByDay.propTypes = {
  day: PropTypes.instanceOf(moment).isRequired,
  selectDay: PropTypes.func.isRequired,
  isFetching: PropTypes.bool
};
DatesPicker.ByWeek.propTypes = {
  week: PropTypes.number.isRequired,
  selectWeek: PropTypes.func.isRequired,
  setPendingTimeSlot: PropTypes.func.isRequired,
  isFetching: PropTypes.bool
};
DatesPicker.ByMonth.propTypes = {
  month: PropTypes.number.isRequired,
  setMonth: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
  setYear: PropTypes.func,
  isFetching: PropTypes.bool
};