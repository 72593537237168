import PropTypes from "prop-types";
import React from "react";

const DateSearch = ({ dateFrom, dateTo, setDateFilters }) => {
  return (
    <div className="search-fields-container">
      <div className="search-fields-column search-field">
        <p style={{ padding: "0px", margin: "1px" }}>Start Date</p>
        <input
          type="date"
          value={dateFrom}
          onChange={({ target }) => {
            setDateFilters(prevFilters => {
              const value = target.value;
              return {
                ...prevFilters,
                date_from: value
              };
            });
          }}
        />
      </div>
      <div className="search-fields-column search-field">
        <p style={{ padding: "0px", margin: "1px" }}>To</p>
        <input
          type="date"
          value={dateTo}
          onChange={({ target }) => {
            setDateFilters(prevFilters => {
              const value = target.value;
              return {
                ...prevFilters,
                date_to: value
              };
            });
          }}
        />
      </div>
    </div>
  );
};

DateSearch.propTypes = {
  dateFrom: PropTypes.instanceOf(Date),
  dateTo: PropTypes.instanceOf(Date),
  setDateFilters: PropTypes.func
}

export default DateSearch;