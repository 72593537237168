import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RowSelectCell } from "hooks/useRowSelectNew";
import moment from "moment";
import IoMdEdit from "react-icons/lib/io/edit";
import { TimeSlotContext } from "../../availability-scheduler/use-availabilityScheduler";

const ManagerViewTableRow = row => {
  const {
    AppointmentId,
    StartDate,
    Duration,
    CoachName,
    CoachId,
    Companies,
    Branches,
    ChatType,
    AssignedPatients,
    Topic,
    Status
  } = row;

  const { setEditSlot } = useContext(TimeSlotContext);
  const [slot, setSlot] = useState({});

  useEffect(()=>{
    setSlot({
      ...row,
      start_time: moment(row.StartDate).format("L LT"),
      end_time: moment(row.EndDate).format("L LT")
    })
  },[AppointmentId])

  return (
    <tr>
      <RowSelectCell id={AppointmentId} />
      <td style={{ textAlign: "center"}} key={`edit-cell-${AppointmentId}`}>
        <span
          key={`edit-button-${AppointmentId}`}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEditSlot(slot);
          }}
        >
          <IoMdEdit size={20} />
        </span>
      </td>
      <td>{moment(StartDate).format("MM/DD/YYYY hh:mm A")}</td>
      <td>{Duration}</td>
      <td>
        <Link to={`/users/${CoachId}`}>{CoachName}</Link>
      </td>
      <td>{Topic}</td>
      <td>
        {AssignedPatients.map((x, i) => {
          let separator = "";
          if (i !== AssignedPatients.length - 1) separator = ", ";
          return (
            <span key={x.user_id}>
              <Link to={`/patients/${x.user_id}`}>{x.patient_name}</Link>
              {separator}
            </span>
          );
        })}
      </td>
      <td>
        {Companies.map((x, i) => {
          let separator = "";
          if (i !== Companies.length - 1) separator = ", ";
          return (
            <span key={x.id}>
              <Link to={`/companies/${x.id}`}>{x.name}</Link>
              {separator}
            </span>
          );
        })}
      </td>
      <td>
        {Branches.map((x, i) => {
          if (x) {
            let separator = "";
            if (i !== Branches.length - 1) separator = ", ";
            return (
              <span key={x.id}>
                {x.name}
                {separator}
              </span>
            );
          }
        })}
      </td>
      <td>{ChatType}</td>

      <td>{Status}</td>
    </tr>
  );
};

export default ManagerViewTableRow;
