import React from "react";
import PropTypes from "prop-types";
import { TimeSlotContext } from "./use-availabilityScheduler";
import { gridPosTodate } from "./scheduler-helpers";
import moment from "moment";

const TIME_SLOTS = Array.from(Array(60).keys());

const TimeSlot = React.memo(
  ({ row, col, week, timeZone, coachAvailability }) => {
    const isBeforeToday = day =>
      moment(day, "MM/DD/YYYY").isBefore(moment().tz(timeZone));

    const isBetweenWorkingTime = (coachAvailability, value) => {
      const DayFromGridPosition = value.day();
      const schedulesThatDay = coachAvailability?.filter(
        x => x.day === DayFromGridPosition
      );
      if (!schedulesThatDay || schedulesThatDay?.length == 0) return false;

      let { start, end, lunch_start, lunch_end } = schedulesThatDay[0];

      const TimeFromGridPosition = value.format("HH:mm");
      start = moment(start, "hh:mm a").format("HH:mm");
      end = moment(end, "hh:mm a").format("HH:mm");
      lunch_start = moment(lunch_start, "hh:mm a").format("HH:mm");
      lunch_end = moment(lunch_end, "hh:mm a").format("HH:mm");

      const isLunchTime =
        lunch_start <= TimeFromGridPosition && TimeFromGridPosition < lunch_end;
      const isWorkingTime =
        start <= TimeFromGridPosition && TimeFromGridPosition < end;

      if (isLunchTime) {
        return "lunch";
      } else if (isWorkingTime) {
        return "work";
      } else {
        return false;
      }
    };
    const isMiddleOfLunchTime = (coachAvailability, value) => {
      const DayFromGridPosition = value.day();
      const schedulesThatDay = coachAvailability?.filter(
        x => x.day === DayFromGridPosition
      );
      if (!schedulesThatDay || schedulesThatDay?.length == 0) return false;

      let { lunch_start, lunch_end } = schedulesThatDay[0];

      const TimeFromGridPosition = moment(value);
      lunch_start = moment(lunch_start, "hh:mm a");
      lunch_end = moment(lunch_end, "hh:mm a");

      const diffBetweenStartAndEnd = lunch_end.diff(lunch_start, "minutes");
      const diffIn15MinutesBlock =
        Math.round(Math.round(diffBetweenStartAndEnd / 15) / 2) * 15;
      const roundedDiff = diffIn15MinutesBlock - 15;
      const middleTime = lunch_start.clone().add(roundedDiff, "minutes");
      const currRowIsMiddleTime =
        TimeFromGridPosition.format("HH:mm") == middleTime.format("HH:mm");

      if (currRowIsMiddleTime) {
        return true;
      } else {
        return false;
      }
    };

    const { selected } = React.useContext(TimeSlotContext);
    const active = selected.col === col;
    const isSelected = selected.row1 === row && selected.col === col;

    const gridPositionTodate = gridPosTodate(row, col, week, timeZone);
    const disabled = isBeforeToday(gridPositionTodate);
    const isWorkOrLunchTime = isBetweenWorkingTime(
      coachAvailability,
      gridPositionTodate
    );
    const isMiddleOfLunch =
      isWorkOrLunchTime == "lunch" &&
      isMiddleOfLunchTime(coachAvailability, gridPositionTodate);

    return isWorkOrLunchTime === "lunch" ? (
      <div
        style={{ gridRow: row + 1 }}
        className={`time-slot lunch-time ${active ? "selected" : ""} ${isSelected ? "initial-row" : ""
          } ${disabled ? "disabled" : ""}
              `}
      >
        <p style={{ textAlign: "center" }}>{isMiddleOfLunch && "Lunch"}</p>
      </div>
    ) : (
      <div
        style={{ gridRow: row + 1 }}
        className={`time-slot ${active ? "selected" : ""} ${isSelected ? "initial-row" : ""
          } ${disabled ? "disabled" : ""}
          ${isWorkOrLunchTime === "work" ? "work-time" : ""}
            `}
      />
    );
  }
);

TimeSlot.propTypes = {
  row: PropTypes.number.isRequired,
  col: PropTypes.number.isRequired,
  week: PropTypes.number.isRequired,
  timeZone: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  coachAvailability: PropTypes.array
};

const TimeSlots = React.memo(({ week, timeZone, coachAvailability }) =>
  Array.from(Array(7).keys()).map(col =>
    TIME_SLOTS.map(row => (
      <TimeSlot
        key={`${col}-${row}`}
        row={row}
        col={col}
        week={week}
        timeZone={timeZone}
        coachAvailability={coachAvailability}
      />
    ))
  )
);

TimeSlots.propTypes = {
  startRow: PropTypes.number,
  week: PropTypes.number.isRequired,
  timeZone: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  coachAvailability: PropTypes.array
};

export default TimeSlots;
