import React from "react";
import PropTypes from "prop-types";
import { getGridRowSpan } from "../scheduler-helpers";
import { TimeSlotContextDay } from "./use-availabilitySchedulerDay";

const getChatStatusClass = (appointmentStatus = "") => {
  if (appointmentStatus !== null) {
    return appointmentStatus.replace(" ", "-").toLowerCase();
  } else {
    return "";
  }
};

const ScheduledSlotDay = React.memo(({ slot }) => {
  const { selected, setSelected } = React.useContext(TimeSlotContextDay);
  const [mouseDown, setMouseDown] = React.useState(false);
  const gridSpan = getGridRowSpan(slot.row1, slot.row2);

  return (
    <div
      key={slot.AppointmentId}
      className={`scheduled-slot active ${
        selected && selected.AppointmentId === slot.AppointmentId
          ? "selected"
          : ""
      } ${getChatStatusClass(slot.Status)}`}
      style={{
        gridColumn: `${1} / span 1`,
        gridRow: `${slot.row1 + 1} / span ${gridSpan}`
      }}
      title={slot.Description}
      onMouseUp={() => {
        setMouseDown(false);
      }}
      onMouseDown={e => {
        e.stopPropagation();
        setMouseDown(true);
      }}
    >
      <div className="scheduled-slot-info">
        <div className="slot-time">
          {slot.IsTimeOff ? (
            <span>Time Off</span>
          ) : (
            <>
              <span>{slot.Topic}</span>
              <span>
                {slot?.AssignedPatients?.map(patient => patient.patient_name) ??
                  ""}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

ScheduledSlotDay.propTypes = {
  slot: PropTypes.shape({
    AppointmentId: PropTypes.string.isRequired,
    col: PropTypes.number.isRequired,
    ChatType: PropTypes.string.isRequired,
    row1: PropTypes.number.isRequired,
    row2: PropTypes.number.isRequired,
    start_time: PropTypes.string.isRequired,
    end_time: PropTypes.string.isRequired,
    slots_remaining: PropTypes.number,
    MaxInvitees: PropTypes.number,
    Status: PropTypes.string,
    Description: PropTypes.string,
    Topic: PropTypes.string,
    IsTimeOff: PropTypes.bool,
    AssignedPatients: PropTypes.array
  }).isRequired
};

export default ScheduledSlotDay;
