import React from "react";
import PropTypes from "prop-types";
import { Waiting } from "components/ui";
import QueryTableContext from "./context";
import TableHeader from "./table-header";
import TableFooter from "./table-footer";
import TableBody from "./table-body";

const ApiQueryTable = ({
  columns,
  children,
  isFetching,
  fetchData,
  query,
  queryDispatcher,
  rows,
  containerClass,
  TableRow,
  rowKey,
  Controls,
  data,
  perPageOptions,
  customNotFoundMessage,
  renderFooter = true
}) => {
  return (
    <QueryTableContext.Provider
      value={{
        query,
        queryDispatcher,
        fetchData,
        rows,
        data
      }}
    >
      {children}
      <div className="query-table-container scrollable">
        <div className="query-header-controls">{Controls}</div>
        <div className={containerClass}>
          {isFetching && (
            <div className="table-loading-spinner">
              <Waiting size="50px" style={{ position: "sticky" }} />
            </div>
          )}
          <table className={`${isFetching ? "loading" : ""}`}>
            <TableHeader columns={columns} />
            <TableBody rowKey={rowKey} TableRow={TableRow} />
          </table>
          {!isFetching && rows.length == 0 && (
            <h1 style={{ marginLeft: "5px" }}>
              {customNotFoundMessage
                ? customNotFoundMessage
                : "No patients found with those filters"}
            </h1>
          )}
        </div>
        {renderFooter ? <TableFooter perPageOptions={perPageOptions} /> : ""}
      </div>
    </QueryTableContext.Provider>
  );
};

ApiQueryTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetching: PropTypes.bool,
  query: PropTypes.object,
  queryDispatcher: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.shape({
    count: PropTypes.number,
    per: PropTypes.number,
    page: PropTypes.number
  }).isRequired,
  containerClass: PropTypes.string,
  TableRow: PropTypes.elementType,
  rowKey: PropTypes.string.isRequired,
  Controls: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
  usePagination: PropTypes.bool,
  perPageOptions: PropTypes.arrayOf(PropTypes.shape({})),
  fetchData: PropTypes.func,
  children: PropTypes.node,
  customNotFoundMessage: PropTypes.string,
  renderFooter: PropTypes.func
};

export default ApiQueryTable;
