import React from "react";
import PropTypes from "prop-types";
import AvailabilitySchedulerDay from "../availability-scheduler/day-scheduler/AvailabilitySchedulerDay";

const AppointmentSearch = ({originalDate, setNewStartDate}) => {
  return (
    <div style={{ display: "grid", gridGap: 10, gridRows: "1fr 2fr" }}>
      <AvailabilitySchedulerDay
        originalDate={originalDate} setNewStartDate={setNewStartDate}
      ></AvailabilitySchedulerDay>
    </div>
  );
};

AppointmentSearch.defaultProps = {};

AppointmentSearch.propTypes = {
  setNewStartDate: PropTypes.func.isRequired,
  originalDate: PropTypes.string.isRequired,
};

export default AppointmentSearch;
