import { makeFetchCreator } from "actions/fetch";
import { put, post } from "utils/api";
import { message, errorMessage } from "actions/message";
import { browserHistory } from "browser-history";
import { getApiErrorMessage, transformUserRole } from "utils/misc";
// import fetch from "isomorphic-fetch";
import * as storage from "utils/storage";
import { host } from "environment";
import { getCurrentTimeZone, updateTimeZone } from "utils/dates";

export const Types = {
  CREATE_NEW_PASSWORD: "CREATE_NEW_PASSWORD",
  LOAD_STORED_USER: "LOAD_STORED_USER",
  LOGIN_USER_FAILED: "LOGIN_USER_FAILED",
  LOGIN_USER_REQUEST: "LOGIN_USER_REQUEST",
  LOGIN_USER_RESPONSE: "LOGIN_USER_RESPONSE",
  LOGOUT_USER_INACTIVE: "LOGOUT_USER_INACTIVE",
  LOGOUT_USER_INACTIVE_CHECK: "LOGOUT_USER_INACTIVE_CHECK",
  LOGOUT_USER_REQUEST: "LOGOUT_USER_REQUEST",
  LOGOUT_USER_RESPONSE: "LOGOUT_USER_RESPONSE",
  REQUEST_LOAD_USER: "REQUEST_LOAD_USER",
  STORE_USER_EMAIL: "STORE_USER_EMAIL",
  UPDATE_EXPIRED_PASSWORD: "UPDATE_EXPIRED_PASSWORD",
  RECEIVE_UPDATE_EXPIRED_PASSWORD: "RECEIVE_UPDATE_EXPIRED_PASSWORD",
  FAILURE_UPDATE_EXPIRED_PASSWORD: "FAILURE_UPDATE_EXPIRED_PASSWORD",
  SEND_RESET_PASSWORD_EMAIL: "SEND_RESET_PASSWORD_EMAIL",
  TWO_FACTOR_AUTH_REQUIRED: "TWO_FACTOR_AUTH_REQUIRED",
  TWO_FACTOR_AUTH_APPROVED: "TWO_FACTOR_AUTH_APPROVED",
  TWO_FACTOR_AUTH_LOGIN: "TWO_FACTOR_AUTH_LOGIN",
  AZURE_AD_SSO: "AZURE_AD_SSO"
};

export function requestLoadUser() {
  return dispatch => {
    const serializedUser = window.localStorage.getItem("evergreen");
    const user = JSON.parse(serializedUser);
    if (user) dispatch(loadStoredUser(user));
  };
}

export const loadStoredUser = user => ({
  type: Types.LOAD_STORED_USER,
  payload: { user }
});

export const requestUserLogin = (email, password, device_token) => ({
  type: Types.LOGIN_USER_REQUEST,
  payload: {
    credentials: { email, password, device_token }
  }
});

export const responseUserLogin = user => ({
  type: Types.LOGIN_USER_RESPONSE,
  payload: { user }
});

export const errorUserLogin = message => ({
  type: Types.LOGIN_USER_FAILED,
  error: true,
  message
});

export const requestUserLogout = () => ({
  type: Types.LOGOUT_USER_REQUEST
});

export const inactivityLogout = () => ({
  type: Types.LOGOUT_USER_INACTIVE
});

export const responseUserLogout = () => ({
  type: Types.LOGOUT_USER_RESPONSE
});
export const errorUserLogout = payload => ({
  type: Types.LOGOUT_USER_REQUEST,
  error: true,
  payload
});

export const userCheckInactivity = () => ({
  type: Types.LOGOUT_USER_INACTIVE_CHECK
});

export const storeUserEmail = email => ({
  type: Types.STORE_USER_EMAIL,
  email
});

export const CREATE_NEW_PASSWORD = "CREATE_NEW_PASSWORD";
const resetPasswordFetch = makeFetchCreator({
  url: "user/change_password",
  baseType: CREATE_NEW_PASSWORD,
  method: put
});

export const twoFactorAuthTokenSignIn =
  ({ token }) =>
  async dispatch => {
    try {
      const user = await post("mfaApprove", { token });
      dispatch({ type: Types.TWO_FACTOR_AUTH_LOGIN, user });
      window.close();
      return true;
    } catch (err) {
      return false;
    }
  };

export const twoFactorAuthCodeSignIn =
  ({ email, authCode }) =>
  async dispatch => {
    try {
      const user = await post("authCodeValidate", { email, authCode });
      dispatch({ type: Types.TWO_FACTOR_AUTH_LOGIN, user });
      return true;
    } catch (err) {
      return false;
    }
  };

export const resetPassword = params => dispatch =>
  dispatch(resetPasswordFetch({ payload: params }))
    .then(() => {
      dispatch(message("Password has been reset. Please log in."));
      browserHistory.push({ pathname: "/login", state: { force: true } });
    })
    .catch(err => {
      const message = getApiErrorMessage(
        err,
        "There was a problem submitting your new password. Please try again."
      );
      dispatch(errorMessage(message));
    });

const resetEmailFetch = makeFetchCreator({
  url: "password",
  baseType: Types.SEND_RESET_PASSWORD_EMAIL,
  method: post
});

export const requestPasswordResetEmail = params => dispatch =>
  dispatch(resetEmailFetch({ payload: params }))
    .then(response => {
      dispatch(
        message(
          response?.status ??
            "If you have an account with us, an email has been sent."
        )
      );
    })
    .catch(err => {
      const message = getApiErrorMessage(
        err,
        "There was a problem requesting a password reset email."
      );
      dispatch(errorMessage(message));
    });

const requestResetExpiredPassword = makeFetchCreator({
  url: `user/change_expired_password`,
  baseType: Types.UPDATE_EXPIRED_PASSWORD,
  method: post
});

export const resetExpiredPassword = params => dispatch =>
  dispatch(requestResetExpiredPassword(params))
    .then(() => {
      dispatch(message("Password has been reset. Please log in."));
      browserHistory.push({
        pathname: "/login",
        state: { force: true }
      });
    })
    .catch(err => {
      const message = getApiErrorMessage(
        err,
        "There was a problem submitting your new password. Please try again."
      );
      dispatch(errorMessage(message));
    });

export const loginWithToken =
  ({ token }) =>
  async dispatch => {
    try {
      const response = await fetch(`${host}jwt`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json"
        },
        body: JSON.stringify({})
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const user = await response.json();
      if (user) {
        const userLoggedIn = transformUserRole(user);

        const currentTimeZone = await getCurrentTimeZone();
        const timeZoneSaved = await updateTimeZone(userLoggedIn.authentication_token, currentTimeZone)
        if(timeZoneSaved)
          userLoggedIn.timezone = timeZoneSaved;

        storage.put("evergreen", JSON.stringify(userLoggedIn));
        dispatch(responseUserLogin(transformUserRole(user)));
        browserHistory.push("/dashboard");
      }
    } catch (err) {
      browserHistory.push("/login");
    }
  };

export const loginWithAzureADToken =
  ({ idToken, tenantId, email }) =>
  async dispatch => {
    try {
      const response = await fetch(`${host}azure_sso`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "content-type": "application/json"
        },
        body: JSON.stringify({
          idToken,
          tenantId,
          email
        })
      });
      if (!response.ok) {
        dispatch(
          errorMessage(
            "Login using Office365 account failed, please contact system admin for assistance."
          )
        );
        throw Error(response.statusText);
      }
      const user = await response.json();
      dispatch({ type: Types.AZURE_AD_SSO, user });
      browserHistory.push("/dashboard");
    } catch (err) {
      dispatch(errorMessage("Login using Office365 account failed"));
      browserHistory.push("/login");
    }
  };
