import React from "react";
import PropTypes from "prop-types";
import useMousePosition from "../use-mouse-position";
import { formatTimeFromHour } from "utils/dates";
import {
  gridPosToDateSingleDay,
  isBeforeNow
} from "../scheduler-helpers";
import { TimeSlotContextDay } from "./use-availabilitySchedulerDay";
import { Spinner } from "components/ui";

const Times = Array.from(Array(24).keys()).filter(t => t >= 5 && t <= 19);
const CellHeight = 15;
const CellWidth = 180;
const getRow = y => Math.floor(y / CellHeight);
const getCol = x => Math.floor(x / CellWidth);

const CalendarDay = React.memo(({ children, setNewStartDate, selectedDate, timeZone }) => {
  const [mousePosition, schedulerRef] = useMousePosition();
  const {
    setSelected,
    gettingTimeSlots,
    creatingOrEditingTimeSlots
  } = React.useContext(TimeSlotContextDay);

  return (
    <div className="calendar-day" style={{ position: "relative" }}>
      <div className="times">
        {Times.map(t => (
          <div key={t} style={{ gridRow: t - 4 }}>
            {formatTimeFromHour(t)}
          </div>
        ))}
      </div>
      <div
        ref={schedulerRef}
        className="time-slots-day-container"
        onClick={e => {
          e.preventDefault();
        }}

        onMouseDown={() => {
          const row1 = getRow(mousePosition.y);
          const col = getCol(mousePosition.x);
          const newDate = gridPosToDateSingleDay(row1, selectedDate, timeZone)
          setNewStartDate(newDate)
          if (!isBeforeNow(newDate, timeZone)) {
            setSelected(() => ({
              row1,
              col
            }));
          }
        }}

      >
        {children}
      </div>
      {(gettingTimeSlots || creatingOrEditingTimeSlots) && (
        <div className="calendar-spinner-container">
          <Spinner />
        </div>
      )}
    </div>
  );
});

CalendarDay.propTypes = {
  children: PropTypes.node,
  timeZone: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  setNewStartDate: PropTypes.func,
  selectedDate: PropTypes.string
};

export default CalendarDay;
