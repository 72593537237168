import React from "react";
import PropTypes from "prop-types";
import useMousePosition from "../availability-scheduler/use-mouse-position";
import { formatTimeFromHour } from "utils/dates";
import {
  managerViewConvertDatesToGridSpan,
  managerViewConvertGridSpanToDates1,
  managerViewGridPosTodate,
  isInvalidSlot,
  isBefore
} from "../availability-scheduler/scheduler-helpers";
import { TimeSlotContext } from "../availability-scheduler/use-availabilityScheduler";
import { Tooltip } from "components/ui/tool-tip/tooltip";

const Times = Array.from(Array(24).keys()).filter(t => t >= 5 && t <= 19);
const CellHeight = 15;
const CellWidth = 180;
const getRow = y => Math.floor(y / CellHeight);
const getCol = x => Math.floor(x / CellWidth);

const CoachCalendar = React.memo(({ children, willEditTimeSlot, timeZone }) => {
  const [mousePosition, schedulerRef] = useMousePosition();

  const {
    setSelected,
    editSlot,
    setEditSlot,
    selected,
    setPendingTimeSlot,
    managerViewCoaches,
    managerAppointments,
    date,
    isBetweenWorkingTime,
    setCoachViewCoachID
  } = React.useContext(TimeSlotContext);

  return (
    <div className="calendar" style={{ position: "relative" }}>
      <div className="empty-corner"/>
      <div className="calendar-daysofweek-header">
        {
          managerViewCoaches.length > 0 && managerViewCoaches.map((coach) => {
            return (
              <div key={coach.id} className="calendar-day-of-week">
                <span 
                  style={{cursor: "pointer"}}
                  onClick={() => setCoachViewCoachID(coach.id) }>
                  {!coach.active &&
                    <Tooltip id='coach tooltip' className="single-stat-tooltip" effect="solid" message='Coach is inactive.'>
                      <p style={ {color:'red'}}>{coach.name}</p>
                    </Tooltip>
                  }        
                  {coach.active && coach.name}
                </span>
              </div>
            )
          })
        }
      </div>
      <div className="times">
        {Times.map(t => (
          <div key={t} style={{ gridRow: t - 4 }}>
            {formatTimeFromHour(t)}
          </div>
        ))}
      </div>
      <div
        ref={schedulerRef}
        className="time-slots-container"
        onClick={e => {
          e.preventDefault();
        }}
        onMouseLeave={() => {
          setSelected({
            row1: null,
            col: null
          });
        }}
        onMouseDown={() => {
          const row1 = getRow(mousePosition.y);
          const col = getCol(mousePosition.x);
          const coachIsActive = managerViewCoaches[col].active;

          const selectedStartTime = managerViewGridPosTodate(row1, date, timeZone);
          if (!isBefore(selectedStartTime) && coachIsActive) {
            setSelected(() => ({
              row1,
              col
            }));
          }
          if (editSlot && !willEditTimeSlot) setEditSlot();
        }}
        onMouseUp={() => {
          if (selected.col !== null && selected.row1 !== null) {
            const row2 = getRow(mousePosition.y + CellHeight);
            const startRow = selected.row1 <= row2 ? selected.row1 : row2 - 1;
            const endRow = row2 >= selected.row1 ? row2 : selected.row1 + 1;
            const overlappingAppointment = managerAppointments
              .map(pa => managerViewConvertDatesToGridSpan(managerViewCoaches, pa))
              .filter(({ col }) => col === selected.col)
              .find(slot =>
                isInvalidSlot(
                  { row1: slot.row1, row2: slot.row2 },
                  { row1: startRow, row2: endRow }
                )
              );

            const newTimeSlot = {
              ...managerViewConvertGridSpanToDates1({
                row1: startRow,
                row2: endRow,
                date
              }),
              coachId: managerViewCoaches[selected.col].id
            };

            const isWorkingTime = isBetweenWorkingTime(managerViewGridPosTodate(startRow, date, timeZone), managerViewCoaches[selected.col].id);
            if (!overlappingAppointment && isWorkingTime == "work") {
              setPendingTimeSlot(newTimeSlot);
            }
            setSelected({
              row1: null,
              col: null
            });
          }
        }}
      >
        {children}
      </div>
    </div>
  );
});

CoachCalendar.propTypes = {
  children: PropTypes.node,
  willEditTimeSlot: PropTypes.bool,
  timeZone: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
};

export default CoachCalendar;
