import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFetch } from "hooks";
import { post, put } from "utils/api";
import { Spinner } from "components/ui";
import { errorMessage, message } from "actions/message";
import { getApiErrorMessageCustomPathEnd } from "utils/misc";
import { AvailableCoachesConfirmationModal } from "./confirmation-modal.jsx";
import { useDispatch } from "react-redux";

const AvailableCoachesModal = ({ onClose, onSubmit, appointmentId }) => {
  const dispatch = useDispatch();

  const [availableCoaches, setAvailableCoaches] = useState([]);
  const [selectedCoach, setSelectedCoach] = useState({
    text: "Select Coach",
    value: ""
  });

  const { fetchData: fetchCoaches, isFetching } = useFetch({
    apiFn: () =>
      post(`appointments/manager-view/available-coaches/${appointmentId}`),
    defaultValue: [],
    onError: error => {
      dispatch(
        errorMessage(
          `Failed to fetch coaches: ${getApiErrorMessageCustomPathEnd(
            error,
            "message"
          )}`
        )
      );
    }
  });

  const { fetchData: updateCoach, isFetching: isFetchingCoach } = useFetch({
    apiFn: () =>
      put(
        `appointments/manager-view/${appointmentId}/move/${selectedCoach.value}`
      ),
    defaultValue: [],
    onError: error => {
      dispatch(
        errorMessage(
          `Failed to Update coach: ${getApiErrorMessageCustomPathEnd(
            error,
            "message"
          )}`
        )
      );
    },
    onSuccess: () => {
      dispatch(message("Succesfully updated Coach"));
      onClose();
      onSubmit();
    }
  });

  const getCoaches = async () => {
    const res = await fetchCoaches(appointmentId);
    if (res) setAvailableCoaches(res.Result);
  };

  const submit = async () => {
    await updateCoach();
  };

  const handleSelectChange = event => {
    const value = event.target.value;
    const text = event.target.options[event.target.selectedIndex].text;
    setSelectedCoach({ text: text, value: value });
  };

  useEffect(() => {
    getCoaches();
  }, []);

  return (
    <div style={{ padding: "1em" }}>
      <h4>Move Appointments to: </h4>
      <div>
        <hr style={{ marginBottom: 0 }} />
        <p style={{ padding: "0px", margin: "1px" }}>New Coach</p>
        {isFetching ? (
          <Spinner />
        ) : (
          <select
            value={selectedCoach.value}
            onChange={e => handleSelectChange(e)}
          >
            <option value="">Select Coach</option>
            {availableCoaches?.map(coach => (
              <option key={coach.value} value={coach.value}>
                {coach.text}
              </option>
            ))}
          </select>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        {isFetchingCoach ? (
          <Spinner />
        ) : (
          <AvailableCoachesConfirmationModal
            disabled={selectedCoach.value === ""}
            coachName={selectedCoach.text}
            submit={submit}
          />
        )}
      </div>
    </div>
  );
};

AvailableCoachesModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  appointmentId: PropTypes.string
};

export default AvailableCoachesModal;
