import React from "react";
import { PureModal } from "components/ui";
import AvailableCoachesModal from "./available-coaches-modal.jsx";
import PropTypes from "prop-types";

export default function MoveAppointment({
  onSubmit,
  appointmentId,
  customStyles,
  text
}) {
  return (
    <div>
      <PureModal
        renderTrigger={({ openModal }) => (
          <div>
            <button
              onClick={openModal}
              className={"move-timeslot-button"}
              style={{
                display: !appointmentId && "none",
                ...customStyles
              }}
              title="Move appointments to another Coach"
            >
              {text}
            </button>
          </div>
        )}
        renderContent={({ closeModal }) => (
          <AvailableCoachesModal
            onClose={() => closeModal()}
            onSubmit={() => onSubmit()}
            appointmentId={appointmentId}
          />
        )}
      ></PureModal>
    </div>
  );
}

MoveAppointment.propTypes = {
  onSubmit: PropTypes.func,
  appointmentId: PropTypes.array,
  customStyles: PropTypes.object,
  text: PropTypes.string
};
