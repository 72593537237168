import React from "react";
import { PureModal } from "components/ui";
import SearchAppointmentsModal from "./search-appointments-modal.jsx";
import PropTypes from "prop-types";

export default function SearchAppointments({ onSubmit }) {
  return (
    <div
      style={{
        gridArea: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
      }}
    >
      <PureModal
        windowProps={{ biggerMaxWidth: true }}
        renderTrigger={({ openModal }) => (
          <button
            type="button"
            className="secondary"
            onClick={openModal}
            title="Search appointments"
            style={{
              padding: "10px",
              margin: "0 20px 0 0"
            }}
          >
            Search
          </button>
        )}
        renderContent={({ closeModal }) => (
          <SearchAppointmentsModal
            onClose={() => closeModal()}
            onSubmit={() => onSubmit()}
          />
        )}
      ></PureModal>
    </div>
  );
}

SearchAppointments.propTypes = {
  onSubmit: PropTypes.func,
  appointments: PropTypes.array
};
