import React from "react";
import { Table } from "hooks/useQuery/api-query";
import useRowSelect, { SelectRowContext } from "hooks/useRowSelectNew";
import TableRow from "./table-row";
import PropTypes from "prop-types";

export default function AppointmentsList({
  queryDispatcher,
  query,
  rows,
  data,
  isFetching
}) {
  const {
    toggleAllRows,
    isRowSelected,
    toggleRow,
    clearSelectedRows,
    selectedRows
  } = useRowSelect(rows, "AppointmentId", query, row => ({
    AppointmentId: `${row.AppointmentId}`,
    Status: `${row.Status}`
  }));

  return (
    <div className="patients-table-tab">
      <SelectRowContext.Provider
        value={{
          toggleAllRows,
          toggleRow,
          selectedRows,
          isRowSelected,
          clearSelectedRows
        }}
      >
        <Table
          query={query}
          data={data}
          queryDispatcher={queryDispatcher}
          isFetching={isFetching}
          containerClass="query-table patients-table dynamic-columns"
          customNotFoundMessage="No appointments found with those filters"
          columns={[
            {
              header: "Date",
              field: "StartDate",
              hasSort: false
            },
            {
              header: "Topic",
              field: "Topic",
              hasSort: false
            },
            {
              header: "Coach",
              field: "CoachName",
              hasSort: false
            },
            {
              header: "Status",
              field: "Status",
              hasSort: false
            },
            {
              header: "Type",
              field: "ChatType",
              hasSort: false
            }
          ]}
          rows={rows}
          TableRow={TableRow}
          rowKey="id"
          renderFooter={false}
          hasSort={false}
        />
      </SelectRowContext.Provider>
    </div>
  );
}

AppointmentsList.propTypes = {
  timeZone: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  resetTimeZoneValue: PropTypes.func,
  queryDispatcher: PropTypes.func,
  query: PropTypes.func,
  rows: PropTypes.array,
  data: PropTypes.array,
  isFetching: PropTypes.func
};
