import React from "react";
import moment from "moment";
import IoArrowLeftA from "react-icons/lib/io/arrow-left-a";
import IoArrowRightA from "react-icons/lib/io/arrow-right-a";
import { IconButton } from "components/ui";
import { TimeSlotContext } from "../availability-scheduler/use-availabilityScheduler";

const DaySelect = React.memo(() => {
  const { selectDate, date } = React.useContext(
    TimeSlotContext
  );
  const handleDayChange = calc => {
    const newDate = calc === 'add' ? moment(date).add(1, 'days') : moment(date).subtract(1, 'days');
    selectDate(newDate);
  };

  return (
    <div className="week-select">
      <IconButton
        icon={<IoArrowLeftA />}
        title="Previous Day"
        onClick={() => handleDayChange('substract')}
        style={{ padding: 4 }}
      />
      <span style={{ fontSize: 15 }}>
        {date.format("MMMM Do YYYY")}
      </span>
      <IconButton
        icon={<IoArrowRightA />}
        title="Next Day"
        onClick={() => handleDayChange('add')}
        style={{ padding: 4 }}
      />
    </div>
  );
});

export default DaySelect;
