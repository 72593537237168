import React from "react";
import CoachViewDatesPicker from "./coach-view-dates-picker";
import CalandarLegend from "../../../availability-scheduler/calendar-legend";
import ViewOptions from "./view-options";
import { TimeSlotContext } from "components/screens/chats/availability-scheduler/use-availabilityScheduler";

const Header = () => {

  const { setCoachViewCoachID } = React.useContext(TimeSlotContext);

  return (
    <div className="calendar-header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <div style={{ width: "250px", display: "flex", alignItems: "center" }}>
        <CoachViewDatesPicker />
      </div>
      <CalandarLegend />
      <ViewOptions />
      <button style={{ margin: 0 }} onClick={() => setCoachViewCoachID("")} className="secondary button">
        Go Back
      </button>
    </div>
  );
};

export default Header;
