import React, { useContext } from "react";
import CalendarMonth from "./calendar-month";
import {
  TimeSlotContext
} from "../../../availability-scheduler/use-availabilityScheduler";

export default function ViewByMonth() {
  const {
    coachViewAppointmentsMonth,
    coachViewAvailabilityMonth,
    coachViewCoachID,
    gettingCoachViewAppointmentsForMonth,
    year,
    month,
    setEditSlot,
    editSlot
  } = useContext(TimeSlotContext)

  return (
    <div className="month-view-wrapper">
      <div className="month-view">
        {coachViewCoachID && (
            <CalendarMonth
              year={year}
              month={month}
              appointments={coachViewAppointmentsMonth}
              availability={coachViewAvailabilityMonth}
              isLoading={gettingCoachViewAppointmentsForMonth}
              setEditSlot={setEditSlot}
              editSlot={editSlot}
            />
        )}
      </div>
    </div>
  );
}
