import React from "react";
import { get, put } from "utils/api";
import moment from "moment-timezone";

const useAvailabilitySchedulerDay = () => {
  const [userTimeSlots, setUserTimeSlots] = React.useState([]);
  const [userAvailability, setUserAvailability] = React.useState([]);
  const [selected, setSelected] = React.useState({
    row1: null,
    col: null
  });
  const [gettingTimeSlots, setGettingTimeSlots] = React.useState(false);
  const [gettingUserAvailability, setGettingUserAvailability] = React.useState(false);
  const [creatingOrEditingTimeSlots, setCreatingOrEditingTimeSlots] =
    React.useState(false);

  const getUserTimeSlots = async selectedDate => {
    setGettingTimeSlots(true);
    const startDate = moment(selectedDate).format("L");
    const endDate = moment(selectedDate).format("L");
    const slots = await put("/appointments/coach-schedule", {
      from_date: startDate,
      to_date: endDate
    });

    const slotsWithFormatedTimes = slots?.map(s => ({
      ...s,
      start_time: moment(s.StartDate).format("L LT"),
      end_time: moment(s.EndDate).format("L LT")
    }))
    setUserTimeSlots(slotsWithFormatedTimes ?? []);
    setGettingTimeSlots(false);
  };

  const getUserAvailability = async () => {
    setGettingUserAvailability(true)
    const res = await get("/users/availability");

    setUserAvailability(res);
    setGettingUserAvailability(false)
  };

  return {
    userTimeSlots,
    userAvailability,
    gettingUserAvailability,
    getUserTimeSlots,
    getUserAvailability,
    selected,
    setSelected,
    gettingTimeSlots,
    creatingOrEditingTimeSlots,
    setCreatingOrEditingTimeSlots
  };
};

export default useAvailabilitySchedulerDay;

export const TimeSlotContextDay = React.createContext();
