import React from "react";
import PropTypes from "prop-types";
import { Card, ListItem } from "components/ui";
import { fromTo } from "utils/misc";
import JoinChatBtn from "./join-chat-btn";
import ClockIcon from "assets/images/clock_gray.png";
import moment from "moment";
import AppointmentActions from "./appointment-actions";
import { Link } from 'react-router-dom';
import "./appointment-actions.scss"

const UpcomingAppointment = ({ appointment, getUpcomingAppointments }) => {
  const is_confirmed = appointment.meeting_status !== "Un-confirmed" && appointment.meeting_status !== "declined";
  const is_cancelled = appointment.meeting_status === "canceled";
  const is_declined = appointment.meeting_status === "declined";
  const userLoggedIn = JSON.parse(window.localStorage.getItem("evergreen"));

  const getStatusColor = (meeting_status) => {

    var statusColor = "white";

    switch (meeting_status) {
      case "completed":
        statusColor = "rgb(19, 180, 255)";
        break;
      case "Confirmed":
        statusColor = "rgb(19, 180, 255)";
        break;
      case "not completed":
        statusColor = "rgb(19, 180, 255)";
        break;
      case "declined":
        statusColor = "rgb(242, 21, 21)";
        break;
      case "rescheduled":
        statusColor = "rgb(19, 180, 255)";
        break;
      case "Un-confirmed":
        statusColor = "rgb(255, 211, 0)";
        break;
      case "canceled":
        statusColor = "rgb(242, 21, 21)";
    }

    return statusColor;
  }

  const diff = moment.duration(
    moment.tz(appointment.start_time, userLoggedIn.timezone).diff(moment().tz(userLoggedIn.timezone))
  );

  const remainingTime = {
    days: moment.utc(diff.as("milliseconds")).format("D") - 1,
    hours: moment.utc(diff.as("milliseconds")).format("H"),
    minutes: moment.utc(diff.as("milliseconds") + 60000).format("mm"),
  };

  return (
    <Card style={{ margin: 8 }}>
      <div className="upcoming-chat-date-container" style={{ background: getStatusColor(appointment.meeting_status) }}>
        <span>{moment(appointment.start_time).format('MMM')}</span>
        <span>{moment(appointment.start_time).format('DD')}</span>
        <span>{moment(appointment.start_time).format('yyyy')}</span>
      </div>
      <div className="upcoming-chat-container">
        <div className="chat-header">
          <span>
            {
              appointment.topic
            }
          </span>
          <span style={{ padding: "0 5px" }}> | </span>
          <span>{appointment.chat_type}</span>

          <div className="chat-time">
            <img src={ClockIcon} alt="" />
            <span>
              {fromTo(
                appointment.start_time,
                appointment.end_time,
                "to"
              )}
            </span>
          </div>
        </div>
        <div className="status-and-remaining-time">
          <div className="status-info">
            {!is_confirmed && !is_declined && <span className="status-info-confirmation-pending">Confirmation pending</span>}
            {is_cancelled && <span className="status-info-cancelled">Canceled</span>}
            {is_declined && <span className="status-info-cancelled">Declined</span>}
          </div>
          {!is_cancelled && !is_declined &&
            (diff > 0 ?
              <div className="chat-remaining-time">
                {"Starts in"} {remainingTime.days} {"days"},{" "}
                {remainingTime.hours} {"Hrs"}, {remainingTime.minutes}{" "}
                {"minutes"}
              </div> :
              <div className="chat-remaining-time">
                <JoinChatBtn {...appointment} is_cancelled />
              </div>)
          }
          
        </div>
      </div>
      <div className="upcoming-chat-content">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100%",
          }}
        >
          {
            appointment.description &&
            <ListItem
              label="Description"
              className="list-item-custom"
              value={appointment.description}
            />
          }
          {appointment.is_product_demo && <ListItem
            label="Current appointment/s"
            className="list-item-custom"
            value={appointment.patients.length + ' | ' }
          />}
          <ListItem
            label="With"
            className="list-item-custom"
          />
          <div className="list-item list-item-custom">
            {
              appointment.patients?.map(({ patient_name, patient_id, status }) => (
                status ?
                  <Link key={patient_id} to={`/patients/${patient_id}`} className="item-value" >
                    <span style={{ color: getStatusColor(status), fontFamily: 'Poppins-Bold' }}>{patient_name}</span>
                  </Link>
                  :
                  <Link key={patient_id} to={`/patients/${patient_id}`} className="item-value">
                    {patient_name}
                  </Link>
              ))
            }
          </div>
        </div>
        <div className="upcoming-chat-actions">
          {!is_cancelled && !is_declined && diff > 0 && <JoinChatBtn {...appointment} is_cancelled />}
          {
            !is_cancelled && !is_declined &&
            <AppointmentActions
              appointmentId={appointment.is_product_demo ? appointment.appointmentId : undefined}
              canBeCanceled={appointment.can_be_cancelled}
              {...appointment}
              getUpcomingAppointments={getUpcomingAppointments}
              createdByPatient={appointment.created_by_patient}
              canReschedule={appointment.can_reschedule}
              patientsToBeNotified={appointment.is_product_demo ? appointment.patients.length : 1}
            />
          }
        </div>
      </div>
    </Card>
  );
};

UpcomingAppointment.propTypes = {
  appointment: PropTypes.object,
  getUpcomingAppointments: PropTypes.func
};

export default UpcomingAppointment;
