import React from "react";
import PropTypes from "prop-types";
import TableHeaderColumn from "./header-column";

const TableHeader = ({ columns }) => {
  return (
    <thead>
      <tr className="query-table-header">
        {columns.map(
          ({
            field,
            filterKey,
            Cell,
            filters,
            filterDownSelect,
            header,
            style,
            title,
            SubFilter,
            headerClassName,
            dropdownPosition,
            FilterComponent,
            hasSort
          }) =>
            Cell ? (
              <Cell key={field} />
            ) : (
              <TableHeaderColumn
                key={field}
                field={field}
                header={header}
                filterKey={filterKey ? filterKey : field}
                filters={filters}
                FilterComponent={FilterComponent}
                style={style}
                title={title}
                headerClassName={headerClassName}
                filterDownSelect={filterDownSelect}
                SubFilter={SubFilter}
                dropdownPosition={dropdownPosition}
                hasSort={hasSort}
              />
            )
        )}
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerClassName: PropTypes.string,
      style: PropTypes.object,
      filters: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.bool,
              PropTypes.number
            ]),
            text: PropTypes.string
          })
        )
      ]),
      filterKey: PropTypes.string, // should be required if filters provided
      header: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
      format: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
      filterDownSelect: PropTypes.bool,
      title: PropTypes.string,
      SubFilter: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.object,
        PropTypes.func
      ]),
      hasSort: PropTypes.bool
    })
  )
};

export default TableHeader;
