import React from "react";
import moment from "moment";
import { TimeSlotContext } from "../availability-scheduler/use-availabilityScheduler";
import { DatePicker } from "components/ui";

const DayPicker = React.memo(() => {

    const { selectDate } = React.useContext(
        TimeSlotContext
    );

    return (
        <div style={{
            display: 'grid',
            gridArea: '2',
            gridTemplateColumns: 'repeat(1, auto)',
            alignItems: 'center',
            justifyContent: 'start',
            flex: '1',
            marginLeft: '50px'
        }}>
            <DatePicker
                onChange={e => { selectDate(moment(e)) }}
                managerView={true}
                containerCss={{ left: 0, top: 0 }}
            />

        </div>
    );
});

export default DayPicker;
