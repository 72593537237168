import React from "react";
import PropTypes from "prop-types";
import { PureModal } from "components/ui";
import AssignablePatients from "./index";

export function AssignablePatientsFormOpenModal() {
    document.getElementById(`assignablePatientsformConfirmationModal`).click();
}

export default function AssignablePatientsFormModal({ formId, additionalFunc }) {

    const onClose = (closeModal) => {
        closeModal();
        if (typeof additionalFunc === 'function') {
            additionalFunc();
        }
    }

    return (
        <PureModal
            windowProps={{ large: true }}
            onClose={typeof additionalFunc === 'function' ? additionalFunc : null}
            renderTrigger={({ openModal }) => <div onClick={() => openModal()} id={`assignablePatientsformConfirmationModal`}></div>}
            renderContent={({ closeModal }) => <AssignablePatients formId={formId} closeModal={onClose.bind(null, closeModal)} />}
        />
    )
}

AssignablePatientsFormModal.propTypes = {
    formId: PropTypes.number,
    additionalFunc: PropTypes.func.isRequired
};