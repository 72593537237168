import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { put, post, del } from "utils/api";
import { MdSchedule, MdAssignment, MdAccountBox } from "react-icons/lib/md";
import { errorMessage, message } from 'actions/message';
import { TimeSlotContext } from "../availability-scheduler/use-availabilityScheduler";
import { PureModal, ToggleSwitch, DatePickerInput, Spinner, ConfirmationDialog } from "components/ui";
import { selectors } from "reducers";
import { useFetch } from "hooks";
import { getApiErrorMessageCustomPathEnd } from "utils/misc";
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from "prop-types";

const CreateTimeOffSlotForCoach = ({ onClose }) => {
  const { editSlot, setEditSlot, getManagerViewAppointments } =
    React.useContext(TimeSlotContext);
  const dispatch = useDispatch();

  //Need to be declared first
  const [newStartDate, setNewStartDate] = useState(
    moment().format("MM/DD/YYYY")
  );
  const [newEndDate, setNewEndDate] = useState(moment().format("MM/DD/YYYY"));
  const [newStartTime, setNewStartTime] = useState("12:00");
  const [newEndTime, setNewEndTime] = useState("12:15");

  const [schedulerOptions, setSchedulerOptions] = React.useState({
    title: "",
    description: editSlot ? editSlot.Description : "",
    startDate: newStartDate,
    startTime: newStartTime,
    endDate: newEndDate,
    endTime: newEndTime
  });

  const user = useSelector(selectors.getUser);
  const [availableCoaches, setAvailableCoaches] = useState([]);
  const [selectedCoach, setSelectedCoach] = useState({
    text: "Select Coach",
    value: editSlot ? editSlot.CoachId : ""
  });

  const { fetchData: fetchCoaches, isFetching } = useFetch({
    apiFn: () =>
      post(
        "dme_portal/appointments/manager/available-coaches-create",
        JSON.stringify([user.id]),
        { "content-type": "application/json" }
      ),
    defaultValue: [],
    onError: error => {
      dispatch(
        errorMessage(
          `Failed to fetch coaches: ${getApiErrorMessageCustomPathEnd(
            error,
            "message"
          )}`
        )
      );
    }
  });

  const getCoaches = async () => {
    const res = await fetchCoaches();
    if (res) {
      setAvailableCoaches(res.Result);
    }
  };

  const handleSelectChange = event => {
    const value = event.target.value;
    const text = event.target.options[event.target.selectedIndex].text;
    setSelectedCoach({ text: text, value: value });
  };

  useEffect(() => {
    getCoaches();
  }, []);

  useEffect(() => {
    if (editSlot) {
      const format = "MM/DD/YYYY hh:mm A";
      const dateStart = moment(editSlot.start_time, format);
      const dateEnd = moment(editSlot.end_time, format);
      setNewStartDate(dateStart.format("MM/DD/YYYY"));
      setNewEndDate(dateEnd.format("MM/DD/YYYY"));
      setNewStartTime(dateStart.format("HH:mm"));
      setNewEndTime(dateEnd.format("HH:mm"));
    }
  }, [editSlot]);

  const createTimeOff = async (
    start_time,
    end_time,
    coach_id,
    { title, description }
  ) => {
    try {
      const { success, message: responseMsg } = await post(
        "/appointments/timeoff",
        {
          start_time: moment(start_time).format("L LT"),
          end_time: moment(end_time).format("L LT"),
          coach_id,
          title,
          description
        }
      );
      if (success) {
        dispatch(message(responseMsg));
      } else {
        dispatch(errorMessage(responseMsg));
      }
      return true;
    } catch (err) {
      dispatch(
        errorMessage(
          err.response?.body?.message ?? "Failed to create Time Off slot"
        )
      );
      return false;
    }
  };

  const editTimeOff = async (
    id,
    start_time,
    end_time,
    coach_id,
    { title, description }
  ) => {
    try {
      const { success, message: responseMsg } = await put(
        `/appointments/timeoff/${id}`,
        {
          start_time: moment(start_time).format("L LT"),
          end_time: moment(end_time).format("L LT"),
          coach_id,
          title,
          description
        }
      );
      if (success) {
        dispatch(message(responseMsg));
      } else {
        dispatch(errorMessage(responseMsg));
      }
      return true;
    } catch (err) {
      dispatch(
        errorMessage(
          err.response?.body?.message ?? "Failed to update Time Off slot"
        )
      );
      return false;
    }
  };

  const inputRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);

  const [modalStartTime, setModalStartTime] = React.useState(
    `${newStartDate} ${newStartTime}`
  );
  const [modalEndTime, setModalEndTime] = React.useState(
    `${newEndDate} ${newEndTime}`
  );

  const [isValidDateRange, setIsValidDateRange] = useState(true);

  const [aMomentIsSelectedInThePast, setAMomentIsSelectedInThePast] =
    useState(false);

  const [isAllDay, setIsAllDay] = useState(false);

  const isBeforeNow = day =>
    moment(day, "MM/DD/YYYY HH:mm").isBefore(
      moment().format("MM/DD/YYYY HH:mm")
    );


  useEffect(() => {
    if (newStartDate && newStartTime) {
      setModalStartTime(`${newStartDate} ${newStartTime}`);
    }
    if (newEndDate && newEndTime) {
      setModalEndTime(`${newEndDate} ${newEndTime}`);
    }
  }, [newStartDate, newEndDate, newStartTime, newEndTime]);

  useEffect(() => {
    if (isBeforeNow(modalStartTime) || isBeforeNow(modalEndTime)) {
      setAMomentIsSelectedInThePast(true);
    } else {
      setAMomentIsSelectedInThePast(false);
    }
  }, [modalStartTime, modalEndTime]);


  const handleDelete = async (closeModal) => {
    closeModal();

    setIsSaving(true);
    const result = await deleteTimeOffSlotById(editSlot.AppointmentId);
    if (result) {
      getManagerViewAppointments();
      onClose();
    }
    setIsSaving(false);
  };

  const deleteTimeOffSlotById = async timeSlotId => {
    try {
      const { success, message: responseMsg } = await del(
        `/appointments/delete-timeoff/${timeSlotId}`,
      );
      if (success) {
        dispatch(message(responseMsg));
      } else {
        dispatch(errorMessage(responseMsg));
      }
      return true;
    } catch (err) {
      dispatch(
        errorMessage(
          err.response?.body?.message ?? "Failed to delete Time Off slot"
        )
      );
      return false;
    }
  };

  const handleDeleteModal = () => {
    document.getElementById("deleteTimeSlotConfirmationModal").click();
  }

  const cancelButton = () => {
    setEditSlot(null)
    onClose();
  }

  const handleSave = async () => {
    setIsSaving(true);
    let successfulCreation = false;
    if (editSlot) {
      successfulCreation = await editTimeOff(
        editSlot.AppointmentId,
        modalStartTime,
        modalEndTime,
        selectedCoach.value,
        schedulerOptions
      );
    } else {
      successfulCreation = await createTimeOff(
        modalStartTime,
        modalEndTime,
        selectedCoach.value,
        schedulerOptions
      );
    }
    if (successfulCreation) {
      getManagerViewAppointments();
      setEditSlot(null)
      onClose();
    } else {
      setIsSaving(false);
    }
  };


  function getHourString(hour, minute, format) {
    return moment({ hour: hour, minute: minute }).format(format);
  }

  function generateTimeOptions(isForStartTime = false) {
    let hours = [];
    for (let hour = 6; hour < 22; hour++) {
      if (hour == 21) {
        if (!isForStartTime) {
          hours.push(
            <option key={`${hour}`} value={getHourString(hour, 0, "HH:mm")}>
              {getHourString(hour, 0, "hh:mm a")}
            </option>
          );
        }
      } else if (hour == 6) {
        isForStartTime &&
          hours.push(
            <option key={`${hour}`} value={getHourString(hour, 0, "HH:mm")}>
              {getHourString(hour, 0, "hh:mm a")}
            </option>
          );
        hours.push(
          <option
            key={`${hour + "15"}`}
            value={getHourString(hour, 15, "HH:mm")}
          >
            {getHourString(hour, 15, "hh:mm a")}
          </option>
        );
        hours.push(
          <option
            key={`${hour + "30"}`}
            value={getHourString(hour, 30, "HH:mm")}
          >
            {getHourString(hour, 30, "hh:mm a")}
          </option>
        );
        hours.push(
          <option
            key={`${hour + "45"}`}
            value={getHourString(hour, 45, "HH:mm")}
          >
            {getHourString(hour, 45, "hh:mm a")}
          </option>
        );
      } else {
        hours.push(
          <option key={`${hour}`} value={getHourString(hour, 0, "HH:mm")}>
            {getHourString(hour, 0, "hh:mm a")}
          </option>
        );
        hours.push(
          <option
            key={`${hour + "15"}`}
            value={getHourString(hour, 15, "HH:mm")}
          >
            {getHourString(hour, 15, "hh:mm a")}
          </option>
        );
        hours.push(
          <option
            key={`${hour + "30"}`}
            value={getHourString(hour, 30, "HH:mm")}
          >
            {getHourString(hour, 30, "hh:mm a")}
          </option>
        );
        hours.push(
          <option
            key={`${hour + "45"}`}
            value={getHourString(hour, 45, "HH:mm")}
          >
            {getHourString(hour, 45, "hh:mm a")}
          </option>
        );
      }
    }
    return hours;
  }

  function handleNewStartTime(chosenTime) {
    const plus15 = moment(chosenTime, "HH:mm")
      .add(15, "minutes")
      .format("HH:mm");
    setNewStartTime(chosenTime);
    setNewEndTime(plus15);
    setIsValidDateRange(
      moment(`${newStartDate} ${chosenTime}`).isBefore(
        moment(`${newEndDate} ${plus15}`)
      )
    );
  }

  const handleAllDayToggle = e => {
    setIsAllDay(e);
    if (e) {
      setNewStartTime(getHourString(6, 0, "HH:mm"));
      setNewEndTime(getHourString(21, 0, "HH:mm"));
    }
  };

  return (
    <div>
      <div className="create-time-slot-modal-container" ref={inputRef}>
        <h4>{editSlot ? "Edit" : ""} Time Off</h4>
        <div>
          <hr />
          {isFetching ? (
            <Spinner />
          ) : (
            <div style={{ display: "flex" }}>
              <div style={{ padding: "1em" }}>
                <MdAccountBox size="20px" />
              </div>
              <select
                value={selectedCoach.value}
                onChange={e => handleSelectChange(e)}
              >
                <option value="">Select Coach</option>
                {availableCoaches?.map(coach => (
                  <option key={coach.value} value={coach.value}>
                    {coach.text}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ padding: "1em" }}>
              <MdAssignment size="20px" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%"
              }}
            >
              <div className="input-label-container">
                <label htmlFor="title_input" className="input-label">
                  Title
                </label>
              </div>
              <input
                className="text-input-styles"
                id="title_input"
                type="text"
                value={schedulerOptions.title}
                onChange={({ target }) =>
                  setSchedulerOptions({
                    ...schedulerOptions,
                    title: target.value
                  })
                }
                style={{ marginTop: "0.2em" }}
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ padding: "1em" }}>
            <MdSchedule size="20px" />
          </div>
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex" }}>
              <DatePickerInput
                placeholder={"MM/DD/YYYY"}
                value={newStartDate}
                handleDayChange={e => {
                  setNewStartDate(moment(e).format("MM/DD/YYYY"));
                  
                  var isBefore = moment(
                    `${moment(e).format("MM/DD/YYYY")} ${newStartTime}`
                  ).isBefore(moment(`${newEndDate} ${newEndTime}`))

                  if(!isBefore)
                    setNewEndDate(moment(e).format("MM/DD/YYYY"));
                }}
                fromDate={moment().toDate()}
              />
              <select
                style={{ width: "7rem", marginLeft: "2em" }}
                value={newStartTime}
                onChange={e => handleNewStartTime(e.target.value)}
                disabled={isAllDay}
              >
                {generateTimeOptions(true)}
              </select>
              <div className="recurrent-switch-container">
                <p className="recurrent-options-text">All Day:</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ToggleSwitch
                    onChange={handleAllDayToggle}
                    value={isAllDay}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <DatePickerInput
                placeholder={"MM/DD/YYYY"}
                value={newEndDate}
                handleDayChange={e => {
                  setNewEndDate(moment(e).format("MM/DD/YYYY"));
                  setIsValidDateRange(
                    moment(`${newStartDate} ${newStartTime}`).isBefore(
                      moment(`${moment(e).format("MM/DD/YYYY")} ${newEndTime}`)
                    )
                  );
                }}
                fromDate={moment().toDate()}
              />
              <select
                style={{ width: "7rem", marginLeft: "2em" }}
                value={newEndTime}
                onChange={e => {
                  setNewEndTime(e.target.value);
                  setIsValidDateRange(
                    moment(`${newStartDate} ${newStartTime}`).isBefore(
                      moment(`${newEndDate} ${e.target.value}`)
                    )
                  );
                }}
                disabled={isAllDay}
              >
                {generateTimeOptions()}
              </select>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "2em" }}>
          <div className="second-icon-container">
            <MdAssignment size="20px" />
          </div>
          <div style={{ flex: 1, position: "relative" }}>
            <label
              htmlFor="description"
              className="input-label"
              style={{
                position: "absolute",
                top: "-10px",
                left: "5px",
                fontSize: "12px"
              }}
            >
              Description
            </label>
            <textarea
              id="description"
              className="text-input-styles"
              style={{
                width: "100%",
                height: "100px",
                padding: "10px",
                boxSizing: "border-box",
                resize: "none"
              }}
              value={schedulerOptions.description}
              onChange={({ target }) =>
                setSchedulerOptions({
                  ...schedulerOptions,
                  description: target.value
                })
              }
            />
            <span
              className="input-label"
              style={{
                position: "absolute",
                top: "-10px",
                right: "5px",
                fontSize: "12px"
              }}
            >
              Optional
            </span>
          </div>
        </div>
        <div
          className="action-buttons-container"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <button
            className="warning create-timeslot-button"
            onClick={() => {
              cancelButton();
            }}
          >
            Cancel
          </button>
          {editSlot ?
            <button
              className="alert remove-timeslot-button"
              onClick={handleDeleteModal}
            >
              Remove
            </button> :
            <span />}
          <button
            className="create-timeslot-button"
            onClick={() => handleSave()}
            disabled={!isValidDateRange || aMomentIsSelectedInThePast || !selectedCoach.value}
          >
            Save
          </button>
        </div>
        {!isValidDateRange && (
          <p className="warning-text">Start date must be set before end date</p>
        )}
        {aMomentIsSelectedInThePast && (
          <p className="warning-text">
            You can’t create a time off in the past
          </p>
        )}
        {
          !selectedCoach.value && <p className="warning-text">
            Coach must be selected
          </p>
        }
        {isSaving && (
          <div
            className="modal-spinner-container"
            style={{ height: "100%", width: "100%" }}
          >
            <Spinner />
          </div>
        )}
      </div>

      <PureModal
        maxWidth="sm"
        fullWidth
        renderTrigger={({ openModal }) => (
          <button
            id="deleteTimeSlotConfirmationModal"
            className="appointment-action-button cancel-appointment-button"
            style={{ display: "none" }}
            onClick={() => {
              openModal();
            }}
          ></button>
        )}
        renderContent={({ closeModal }) => (
          <ConfirmationDialog
            cancelButtonStyles="appointment-action-button cancel-action-button"
            confirmButtonStyles="appointment-action-button reschedule-button"
            continueMsg="Yes"
            cancelMsg="No"
            onCancel={() => {
              closeModal();
            }}
            onConfirm={() => handleDelete(closeModal)}
          >
            <h3 className="edit-timeslot-modal-title">
              Are you sure you want to delete the time off slot?
            </h3>
          </ConfirmationDialog>
        )}
      />
    </div>
  );
};

CreateTimeOffSlotForCoach.propTypes = {
  onClose: PropTypes.func
}

export default CreateTimeOffSlotForCoach;
