import React, {useEffect} from "react";
import { Table } from "hooks/useQuery/api-query";
import useRowSelect, {
  SelectRowContext,
  RowSelectHeaderCell
} from "hooks/useRowSelectNew";
import ManagerViewTableRow from "./manager-view-table-row";
import PropTypes from "prop-types";

export default function AppointmentsList({
  queryDispatcher,
  query,
  rows,
  data,
  isFetching,
  setSelectedAppointment,
  selectedAppointmentId
}) {
  const {
    toggleAllRows,
    isRowSelected,
    toggleRow,
    clearSelectedRows,
    selectedRows
  } = useRowSelect(rows, "AppointmentId", query, row => ({
    AppointmentId: `${row.AppointmentId}`,
    Status: `${row.Status}`
  }));

  useEffect(() => {
    const changedSelectedAppointment = selectedAppointmentId != selectedRows[0]?.AppointmentId
    if(changedSelectedAppointment){
      if(selectedRows?.length == 1){
        setSelectedAppointment(selectedRows[0]?.AppointmentId)
      } else {
        setSelectedAppointment(null)
      }
    }
    
  }, [selectedRows])
  
  return (
    <div className="patients-table-page">
      <SelectRowContext.Provider
        value={{
          toggleAllRows,
          toggleRow,
          selectedRows,
          isRowSelected,
          clearSelectedRows
        }}
      >
        <Table
          query={query}
          data={data}
          queryDispatcher={queryDispatcher}
          isFetching={isFetching}
          containerClass="query-table patients-table dynamic-columns"
          customNotFoundMessage="No appointments found with those filters"
          columns={[
            {
              header: "",
              field: "all",
              Cell: () => <RowSelectHeaderCell text={" "} customBlueHeader={true} />
            },
            {
              header: "",
              field: "edit",
              Cell: () => (
                <td className={"custom-blue-header"} style={{ 
                  width: "15px",
                  top: "-1px",
                  position: "sticky",
              }}>
                  <div> </div>
                </td>
              )
            },
            {
              header: "Start Date",
              field: "StartDate",
              headerClassName: "custom-blue-header",
            },
            {
              header: "Duration",
              field: "DurationInMinutes",
              headerClassName: "custom-blue-header",
            },
            {
              header: "Coach",
              field: "CoachName",
              headerClassName: "custom-blue-header",
            },
            {
              header: "Topic",
              field: "Topic",
              headerClassName: "custom-blue-header",
            },
            {
              header: "Patient",
              field: "AssignedPatients",
              headerClassName: "custom-blue-header",
            },

            {
              header: "Company",
              field: "Companies",
              headerClassName: "custom-blue-header",
            },
            {
              header: "Branch",
              field: "Branches",
              headerClassName: "custom-blue-header",
            },
            {
              header: "Chat Type",
              field: "ChatType",
              headerClassName: "custom-blue-header",
            },

            {
              header: "Status",
              field: "Status",
              headerClassName: "custom-blue-header",

            }
          ]}
          rows={rows}
          TableRow={ManagerViewTableRow}
          rowKey="id"
          perPageOptions={[
            { text: "5", value: 5 },
            { text: "15", value: 15 },
            { text: "50", value: 50 },
            { text: "150", value: 150 },
          ]}
        />
      </SelectRowContext.Provider>
    </div>
  );
}

AppointmentsList.propTypes = {
  timeZone: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  resetTimeZoneValue: PropTypes.func,

  queryDispatcher: PropTypes.func,
  query: PropTypes.object,
  rows: PropTypes.array,
  data: PropTypes.object,
  isFetching: PropTypes.bool,
  setSelectedAppointment: PropTypes.func,
  selectedAppointmentId: PropTypes.string
};
