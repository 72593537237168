import React, { useEffect, useState } from "react";
import { convertDatesToGridSpan } from "./scheduler-helpers";
import WeekSelect from "./week-select";
import TimeSlots from "./time-slots";
import ScheduledSlot from "./scheduled-slot";
import TempScheduledSlot from "./temp-scheduled-slot";
import useAvailabilityScheduler, {
  TimeSlotContext
} from "./use-availabilityScheduler";
import Calandar from "./calendar";
import CalandarLegend from "./calendar-legend";
import CalendarHelpMenu from "./calander-help-menu";
import PropTypes from "prop-types";
import { CreateEditViewTimeSlotModal } from "./create-time-slot-modal";
import { Spinner } from "components/ui";

const AvailabilityScheduler = React.memo(({ timeZone, resetTimeZoneValue }) => {
  const userLoggedIn = JSON.parse(window.localStorage.getItem("evergreen"));
  const [willEditTimeSlot] = useState(false)

  const {
    userTimeSlots,
    userAvailability,
    userAppointmentConfig,
    getUserTimeSlots,
    getUserAvailability,
    getUserAppointmentConfig,
    createTimeSlot,
    createAppointment,
    deleteTimeSlotById,
    pendingTimeSlot,
    setPendingTimeSlot,
    week,
    selectWeek,
    selected,
    setSelected,
    editSlot,
    setEditSlot,
    gettingTimeSlots,
    creatingOrEditingTimeSlots,
    setCreatingOrEditingTimeSlots,
    editAppointmentById,
    gettingCoachViewAppointments,
    getCoachViewAppointments,
    coachViewCoachID,
    gettingUserAvailability,
  } = useAvailabilityScheduler();

  useEffect(() => {
    if (timeZone) {
      getUserTimeSlots(week);
      getUserAppointmentConfig();
      getUserAvailability();
      resetTimeZoneValue();
    }
  }, [timeZone]);

  return (
    <TimeSlotContext.Provider
      value={{
        userTimeSlots,
        userAppointmentConfig,
        getUserTimeSlots,
        createTimeSlot,
        createAppointment,
        deleteTimeSlotById,
        editAppointmentById,
        pendingTimeSlot,
        setPendingTimeSlot,
        week,
        selectWeek,
        selected,
        setSelected,
        editSlot,
        setEditSlot,
        gettingTimeSlots,
        creatingOrEditingTimeSlots,
        setCreatingOrEditingTimeSlots,
        gettingCoachViewAppointments,
        getCoachViewAppointments,
        coachViewCoachID,
        gettingUserAvailability,
      }}
    >
      <div className="scheduler">
        <div className="calendar-wrapper">
          <div className="calendar-header">
            <WeekSelect />
            {/* <ChatTypeLegend /> */}
            <CalandarLegend />
            <CalendarHelpMenu />
          </div>
          <div className="calendar-container">
            <Calandar
              willEditTimeSlot={willEditTimeSlot}
              timeZone={userLoggedIn.timezone}
            >
              {
                <TimeSlots
                  week={week}
                  timeZone={userLoggedIn.timezone}
                  coachAvailability={userAvailability}
                />
              }
              {pendingTimeSlot ? (
                <TempScheduledSlot
                  {...convertDatesToGridSpan(pendingTimeSlot)}
                  setEditSlot={setEditSlot}
                  willEditTimeSlot={willEditTimeSlot}
                  timeZone={userLoggedIn.timezone}
                />
              ) : null}
              {userTimeSlots
                .map(s => ({ ...s, ...convertDatesToGridSpan(s) }))
                .map(slot => (
                  <ScheduledSlot
                    key={slot.slot_id}
                    slot={slot}
                  />
                ))}
            </Calandar>
            {editSlot && !pendingTimeSlot && (
              <CreateEditViewTimeSlotModal
                timeZone={userLoggedIn.timezone}
                data={editSlot}
                isViewing={true}
              />
            )}
            {
              (gettingUserAvailability || gettingTimeSlots || creatingOrEditingTimeSlots ||
                gettingCoachViewAppointments
              ) &&
              <div className="calendar-spinner-container">
                <Spinner />
              </div>
            }
          </div>
        </div>
      </div>
    </TimeSlotContext.Provider>
  );
});

AvailabilityScheduler.propTypes = {
  timeZone: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  resetTimeZoneValue: PropTypes.func
}

export default AvailabilityScheduler;
