import React from "react";
import IoArrowLeftA from "react-icons/lib/io/arrow-left-a";
import IoArrowRightA from "react-icons/lib/io/arrow-right-a";
import { IconButton } from "components/ui";
import PropTypes from "prop-types";

const MonthYearSelector = ({
  selectedYear,
  selectedMonth,
  onDateChange,
  disabled
}) => {
  const handlePrevious = () => {
    const newMonth = selectedMonth === 0 ? 11 : selectedMonth - 1;
    const newYear = selectedMonth === 0 ? selectedYear - 1 : selectedYear;
    onDateChange(newYear, newMonth);
  };

  const handleNext = () => {
    const newMonth = selectedMonth === 11 ? 0 : selectedMonth + 1;
    const newYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;
    onDateChange(newYear, newMonth);
  };

  const handleDateChange = (newYear, newMonth) => {
    onDateChange(newYear, newMonth);
  };

  return (
    <div className="month-selector">
      <IconButton
        icon={<IoArrowLeftA />}
        title="Previous Month"
        onClick={handlePrevious}
        style={{ padding: 4 }}
        disabled={disabled}
      />
      <IconButton
        icon={<IoArrowRightA />}
        title="Next Month"
        onClick={handleNext}
        style={{ padding: 4 }}
        disabled={disabled}
      />

      <input
        type="month"
        value={`${selectedYear}-${selectedMonth + 1 < 10 ? "0" : ""}${
          selectedMonth + 1
        }`}
        onChange={({ target }) => {
          if (target.value) {
            const [year, month] = target.value.split("-");
            handleDateChange(parseInt(year, 10), parseInt(month, 10) - 1);
          }
        }}
        disabled={disabled}
      />
    </div>
  );
};

MonthYearSelector.propTypes = {
  selectedYear: PropTypes.string,
  selectedMonth: PropTypes.number,
  onDateChange: PropTypes.func,
  disabled: PropTypes.bool
}
export default MonthYearSelector;
