import React from "react";
import PropTypes from "prop-types";
import { getGridRowSpan } from "./scheduler-helpers";
import { TimeSlotContext } from "./use-availabilityScheduler";
import moment from "moment";

import { Tooltip } from "components/ui";

const getChatStatusClass = (appointmentStatus = "") => {
  if (appointmentStatus !== null) {
    return appointmentStatus.replace(" ", "-").toLowerCase();
  } else {
    return "";
  }
};

const ScheduledSlot = React.memo(({ slot, isTimeline, byDay }) => {
  const { editSlot, setEditSlot, setPendingTimeSlot } =
    React.useContext(TimeSlotContext);
  const [mouseDown, setMouseDown] = React.useState(false);
  const gridSpan = getGridRowSpan(slot.row1, slot.row2);
  const assignedMultiplePatients = slot?.AssignedPatients?.length > 1;

  const getStyle = () => {
    if (isTimeline) {
      return {
        gridColumn: `${slot.row1 + 1} / span ${gridSpan}`,
        gridRow: `${slot.col + 1} / span 1`
      };
    } else {
      return {
        gridColumn: !byDay && `${slot.col + 1} / span 1`,
        gridRow: `${slot.row1 + 1} / span ${gridSpan}`
      };
    }
  };

  const is15MinChat = gridSpan == 1;

  const starts = moment(slot.start_time, "MM/DD/YYYY h:mm A");
  const ends = moment(slot.end_time, "MM/DD/YYYY h:mm A");

  const exceedsCalendarTimes = starts.isBefore(starts.startOf('day').add(6, 'hours')) || ends.isAfter(starts.startOf('day').add(21, 'hours'))


  if(exceedsCalendarTimes) return null;

  return (
    <div
      key={slot.slot_id}
      className={`scheduled-slot active ${
        editSlot && editSlot.slot_id === slot.slot_id ? "selected" : ""
      } ${byDay ? "by-day" : ""}
      ${getChatStatusClass(slot.Status)}`}
      style={getStyle()}
      onMouseUp={() => {
        if (mouseDown) {
          setPendingTimeSlot(null);
          if (editSlot && editSlot.slot_id === slot.slot_id) setEditSlot(null);
          else {
            setEditSlot(slot);
          }
        }
        setMouseDown(false);
      }}
      onMouseDown={e => {
        e.stopPropagation();
        setMouseDown(true);
      }}
    >
      <div
        className={`scheduled-slot-info ${
          !isTimeline && is15MinChat ? "appointment-15min" : ""
        }`}
      >
        {slot.IsTimeOff ? (
          <span style={{ textAlign: "center" }}>
            {slot?.Description ?? "Time Off"}
          </span>
        ) : (
          <>
            {is15MinChat ? (
              <>
                <Tooltip
                  id={`tooltip-${slot.AppointmentId}`}
                  className="tooltip-assgined-patients"
                  effect="solid"
                  place="top"
                  message={slot?.AssignedPatients.map(p => p.patient_name).join(
                    " - "
                  )}
                >
                  <span
                    className={isTimeline ? "text-ellipsis" : ""}
                    style={{ margin: "0", padding: "0" }}
                  >
                    {slot.Topic}
                  </span>
                </Tooltip>
              </>
            ) : (
              <>
                <span
                  className={isTimeline ? "text-ellipsis" : ""}
                  style={{ margin: "0", padding: "0" }}
                >
                  {slot.Topic}
                </span>

                <span
                  className={isTimeline ? "text-ellipsis" : ""}
                  style={{ margin: "0", padding: "0" }}
                >
                  <a href={`/patients/${slot?.AssignedPatients[0].user_id}`}>
                    {slot?.AssignedPatients[0].patient_name.slice(0, 23)}
                  </a>
                  {assignedMultiplePatients && (
                    <Tooltip
                      id={`tooltip-${slot.AppointmentId}`}
                      className="tooltip-assgined-patients"
                      place="top"
                      message={slot?.AssignedPatients.map(
                        p => p.patient_name
                      ).join(" - ")}
                    >
                      ...
                    </Tooltip>
                  )}
                </span>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
});

ScheduledSlot.propTypes = {
  slot: PropTypes.shape({
    slot_id: PropTypes.number,
    AppointmentId: PropTypes.number.isRequired,
    col: PropTypes.number.isRequired,
    row1: PropTypes.number.isRequired,
    row2: PropTypes.number.isRequired,
    start_time: PropTypes.string.isRequired,
    end_time: PropTypes.string.isRequired,
    slots_remaining: PropTypes.number,
    max_invitees: PropTypes.number,
    Status: PropTypes.string,
    Topic: PropTypes.string,
    AssignedPatients: PropTypes.array,
    Description: PropTypes.string,
    IsTimeOff: PropTypes.bool
  }).isRequired,
  byDay: PropTypes.bool,
  isTimeline: PropTypes.bool
};

export default ScheduledSlot;
