import React, { useEffect, useState } from "react";
import AppointmentsList from "./appointments-list";
import useApiQuery from "hooks/useQuery/api-query";
import ScheduleAppointmentFromProfile from "./schedule-appointment-profile";
import usePatientProfileAppointmentsContext, {
  AppointmentsContext
} from "./use-patientProfileAppointmentsContext";
import { selectors } from "reducers";
import { useSelector, useDispatch } from "react-redux";
import { IoAndroidRefresh } from "react-icons/lib/io";
import { useFetch } from "hooks";
import { put } from "utils/api";
import { errorMessage } from "actions/message";
import { getApiErrorMessageCustomPathEnd } from "utils/misc";
import { transformUiQuery } from "utils/query";
import PropTypes from "prop-types";

const PatientAppointments = ({ patientId }) => {
  const userId = useSelector(selectors.getUserId);
  const { can_be_scheduled, can_schedule } = useSelector(selectors.getUser);

  const {
    coachAppointmentConfig,
    userAppointmentConfig,
    gettingUserAppointmentConfig,
    gettingCoachAppointmentConfig,
    coachesByPatient,
    gettingCoachesByPatient,
    selectedCoach,
    topicCategories,
    gettingTopicCategories,
    setSelectedCoach,
    createAppointment,
    getCoachesByPatient
  } = usePatientProfileAppointmentsContext();

  const dispatch = useDispatch();
  const [allRows, setAllRows] = useState([]);
  const [fetching, setFetching] = useState(false);
  const initialQuery = {
    filters: {
      PatientId: patientId
    }
  };

  const { query, queryDispatcher, rows, data, isFetching, fetchData } =
    useApiQuery(
      "appointments/patient-profile/appointments",
      initialQuery,
      "Result"
    );

  const { fetchData: manuallyFetchData } = useFetch({
    apiFn: () =>
      put(
        `appointments/patient-profile/appointments`,
        transformUiQuery(initialQuery)
      ),
    defaultValue: {},
    onError: error => {
      setFetching(false);
      dispatch(
        errorMessage(
          `Failed to fetch appointments: ${getApiErrorMessageCustomPathEnd(
            error,
            "message"
          )}`
        )
      );
    },
    onSuccess: response => {
      setAllRows(response.Result);
      setFetching(false);
    }
  });

  useEffect(() => setAllRows(rows), [rows]);
  useEffect(() => setFetching(isFetching), [isFetching]);
  useEffect(() => {
    const fetchCoaches = async () => getCoachesByPatient(patientId);
    fetchCoaches();
  }, []);

  return (
    <AppointmentsContext.Provider
      value={{
        coachAppointmentConfig,
        userAppointmentConfig,
        gettingUserAppointmentConfig,
        gettingCoachAppointmentConfig,
        coachesByPatient,
        gettingCoachesByPatient,
        selectedCoach,
        topicCategories,
        gettingTopicCategories,
        setSelectedCoach,
        createAppointment
      }}
    >
      <div style={{ padding: "1rem" }}>
        {can_be_scheduled && can_schedule && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "1rem"
            }}
          >
            <span>
              <ScheduleAppointmentFromProfile
                isButtonTrigger
                patientId={patientId}
                userId={userId}
              />
            </span>
          </div>
        )}

        <AppointmentsList
          data={data}
          isFetching={fetching}
          fetchData={fetchData}
          query={query}
          queryDispatcher={queryDispatcher}
          rows={allRows}
        ></AppointmentsList>

        <IoAndroidRefresh
          color="#4281a6"
          size={20}
          style={{ margin: "10px" }}
          onClick={() => {
            setFetching(true);
            manuallyFetchData();
          }}
        />
      </div>
    </AppointmentsContext.Provider>
  );
};

PatientAppointments.propTypes = {
  patientId: PropTypes.string
};

export default PatientAppointments;
