import React from "react";
import { TimeSlotContext } from "../availability-scheduler/use-availabilityScheduler";

const ViewOptions = React.memo(() => {

    const { managerViewView, setManagerViewView } = React.useContext(
        TimeSlotContext
    );

    const activeStyle = {marginRight:'10px', backgroundColor:'grey', margin:'0em 0em'};
    const inactiveStyle = {marginRight:'10px', color:'black', backgroundColor:'#F5F5F5',  margin:'0em 0em'};

    return (
        <div style={{
            display: 'flex',
            gridArea: '2',
            alignItems: 'center',
            justifyContent: 'start',
            flex: '1',
            marginLeft: '30px'
        }}>
            <button style={managerViewView == "day" ? activeStyle : inactiveStyle} onClick={() => setManagerViewView("day")}> Day </button>
            <button style={managerViewView == "timeline" ? activeStyle : inactiveStyle} onClick={() => setManagerViewView("timeline")}> Timeline </button>
        </div>
    );
});

export default ViewOptions;
