import React from "react";
import { PureModal } from "components/ui";
import CreateTimeOffSlotForCoach from "./create-timeoff-slot-for-coach"
import PropTypes from "prop-types";

export default function CreateTimeOffForCoach({ isButtonTrigger = false }) {
  return (
    <div style={{
      gridArea: "2",
      alignContent: "center",
      justifyContent: "end",
      display: "flex"
    }}>
      {isButtonTrigger ? (
        <PureModal
          windowProps={{ closeable: false }}
          maxWidth="sm"
          renderTrigger={({ openModal }) => (
            <button
              type="button"
              className="timeOffBtn secondary"
              onClick={() => openModal()}
              style={{
                padding: "10px",
                fontSize: "1.2em",
                margin: "auto",
                minWidth: "100px"
              }}
            >
              Add Time Off
            </button>
          )}
          renderContent={({ closeModal }) => (
            <div style={{ paddingLeft: "10px" }}>
              <CreateTimeOffSlotForCoach
                onClose={closeModal}
              />
            </div>
          )}
        ></PureModal>
      ) : (
        <PureModal
          windowProps={{ closeable: false }}
          maxWidth="sm"
          startOpen={true}
          renderTrigger={() => <span />}
          renderContent={({ closeModal }) => (
            <div style={{ paddingLeft: "10px" }}>
              <CreateTimeOffSlotForCoach
                onClose={closeModal}
              />
            </div>
          )}
        ></PureModal>
      )}
    </div>
  );
}

CreateTimeOffForCoach.propTypes = {
  isButtonTrigger: PropTypes.bool,
}