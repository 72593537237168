import React from "react";
import PropTypes from "prop-types";
import { TimeSlotContext } from "../availability-scheduler/use-availabilityScheduler";
import { managerViewGridPosTodate } from "../availability-scheduler/scheduler-helpers";
import moment from "moment";

const TIME_SLOTS = Array.from(Array(60).keys());

const TimeSlot = React.memo(({ row, col, timeZone, byDay, isTimeline }) => {
  const isBeforeToday = day =>
    moment(day, "MM/DD/YYYY").isBefore(moment().tz(timeZone));
  const {
    selected,
    date,
    getManagerViewCoachAvailability,
    getCoachViewAvailability
  } = React.useContext(TimeSlotContext);
  const isSelected = selected.row1 === row && selected.col === col;
  const disabled = isBeforeToday(managerViewGridPosTodate(row, date, timeZone));

  const isBetweenWorkingTime = value => {
    const dayFromGridPosition = date.day();
    const coachAvailability = byDay
      ? getCoachViewAvailability(dayFromGridPosition)
      : getManagerViewCoachAvailability(col, dayFromGridPosition);

    if (coachAvailability == undefined) return;

    let { start, end, lunch_start, lunch_end } = coachAvailability;

    const timeFromGridPosition = value.format("HH:mm");
    start = moment(start, "hh:mm a").format("HH:mm");
    end = moment(end, "hh:mm a").format("HH:mm");
    lunch_start = moment(lunch_start, "hh:mm a").format("HH:mm");
    lunch_end = moment(lunch_end, "hh:mm a").format("HH:mm");

    const isLunchTime =
      lunch_start <= timeFromGridPosition && timeFromGridPosition < lunch_end;
    const isWorkingTime =
      start <= timeFromGridPosition && timeFromGridPosition < end;

    if (isLunchTime) {
      return "lunch";
    } else if (isWorkingTime) {
      return "work";
    }
    return;
  };

  const isMiddleOfLunchTime = value => {
    const dayFromGridPosition = date.day();
    const coachAvailability = byDay
      ? getCoachViewAvailability(dayFromGridPosition)
      : getManagerViewCoachAvailability(col, dayFromGridPosition);
    if (coachAvailability == undefined) return;

    let { lunch_start, lunch_end } = coachAvailability;

    const TimeFromGridPosition = moment(value);
    lunch_start = moment(lunch_start, "hh:mm a");
    lunch_end = moment(lunch_end, "hh:mm a");

    const diffBetweenStartAndEnd = lunch_end.diff(lunch_start, "minutes");
    const diffIn15MinutesBlock =
      Math.round(Math.round(diffBetweenStartAndEnd / 15) / 2) * 15;
    const roundedDiff = diffIn15MinutesBlock - 15;
    const middleTime = lunch_start.clone().add(roundedDiff, "minutes");
    const currRowIsMiddleTime =
      TimeFromGridPosition.format("HH:mm") == middleTime.format("HH:mm");

    if (currRowIsMiddleTime) {
      return true;
    } else {
      return false;
    }
  };

  const isWorkOrLunchTime =
    !disabled &&
    isBetweenWorkingTime(managerViewGridPosTodate(row, date, timeZone));
  const currRowIsMiddleTime = isMiddleOfLunchTime(
    managerViewGridPosTodate(row, date, timeZone)
  );

  const getStyle = () => {
    if (isTimeline) {
      return {
        gridColumn: row + 1
      };
    } else {
      return {
        gridRow: row + 1
      };
    }
  };

  return (
    <div
      style={getStyle()}
      className={`time-slot 
              ${isSelected ? "initial-row" : ""}
              ${disabled ? "disabled" : ""} 
              ${isWorkOrLunchTime == "work" ? "work-time " : ""}
              ${isWorkOrLunchTime == "lunch" ? " lunch-time" : ""}
              ${byDay ? "by-day" : ""}
        `}
    >
      {currRowIsMiddleTime && (
        <p
          style={{
            textAlign: "center",
            alignItems: "center",
            height: "100%",
            display: "flex",
            justifyContent: "center"
          }}
        >
          {"Lunch"}
        </p>
      )}
    </div>
  );
});

TimeSlot.propTypes = {
  row: PropTypes.number.isRequired,
  col: PropTypes.number.isRequired,
  timeZone: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isTimeline: PropTypes.bool,
  byDay: PropTypes.bool,
};

const TimeSlots = React.memo(({ timeZone, isTimeline }) => {
  const { managerViewCoaches } = React.useContext(TimeSlotContext);

  return (
    managerViewCoaches.length > 0 &&
    managerViewCoaches.map(app =>
      TIME_SLOTS.map(row => (
        <TimeSlot
          key={`${app.id}-${row}`}
          row={row}
          col={app.id}
          timeZone={timeZone}
          isTimeline={isTimeline}
        />
      ))
    )
  );
});

TimeSlots.propTypes = {
  timeZone: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isTimeline: PropTypes.bool
};

export const TimeSlots_ByDay = React.memo(({ timeZone }) =>
  Array.from(Array(1).keys()).map(col =>
    TIME_SLOTS.map(row => (
      <TimeSlot
        byDay={true}
        key={`${col}-${row}`}
        row={row}
        col={col}
        timeZone={timeZone}
      />
    ))
  )
);

TimeSlots_ByDay.propTypes = {
  timeZone: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default TimeSlots;
