import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormikField from "components/forms/formik-field";

import moment from "moment";

export const AvaiableDaysRow = ({
  daySchedule,
  errors,
  onLunchStartChanged
}) => {
  function generateLunchTimeOptions() {
    let hours = [];
    if (lunch_start) {
      const start = moment(lunch_start, "HH:mm");
      const startPlus30 = start.clone().add(30, "minutes");
      const startPlus60 = start.clone().add(60, "minutes");

      hours.push(
        <option key={`plus30`} value={startPlus30.format("HH:mm")}>
          {startPlus30.format("hh:mm a")}
        </option>
      );
      hours.push(
        <option key={`plus60`} value={startPlus60.format("HH:mm")}>
          {startPlus60.format("hh:mm a")}
        </option>
      );
    } else {
      hours.push(
        <option key={"unselected"} value={""}>
          Select
        </option>
      );
    }
    return hours;
  }

  function generateTimeOptions(isForStartTime = true) {
    let hours = [
      <option key={"unselected"} value={""}>
        Select
      </option>
    ];

    for (let hour = 6; hour < 22; hour++) {
      if (hour == 21) {
        if (!isForStartTime) {
          hours.push(
            <option key={`${hour}`} value={getHourString(hour, 0, "HH:mm")}>
              {getHourString(hour, 0, "hh:mm a")}
            </option>
          );
        }
      } else if (hour == 6) {
        isForStartTime &&
          hours.push(
            <option key={`${hour}`} value={getHourString(hour, 0, "HH:mm")}>
              {getHourString(hour, 0, "hh:mm a")}
            </option>
          );
        hours.push(
          <option
            key={`${hour + "15"}`}
            value={getHourString(hour, 15, "HH:mm")}
          >
            {getHourString(hour, 15, "hh:mm a")}
          </option>
        );
        hours.push(
          <option
            key={`${hour + "30"}`}
            value={getHourString(hour, 30, "HH:mm")}
          >
            {getHourString(hour, 30, "hh:mm a")}
          </option>
        );
        hours.push(
          <option
            key={`${hour + "45"}`}
            value={getHourString(hour, 45, "HH:mm")}
          >
            {getHourString(hour, 45, "hh:mm a")}
          </option>
        );
      } else {
        hours.push(
          <option key={`${hour}`} value={getHourString(hour, 0, "HH:mm")}>
            {getHourString(hour, 0, "hh:mm a")}
          </option>
        );
        hours.push(
          <option
            key={`${hour + "15"}`}
            value={getHourString(hour, 15, "HH:mm")}
          >
            {getHourString(hour, 15, "hh:mm a")}
          </option>
        );
        hours.push(
          <option
            key={`${hour + "30"}`}
            value={getHourString(hour, 30, "HH:mm")}
          >
            {getHourString(hour, 30, "hh:mm a")}
          </option>
        );
        hours.push(
          <option
            key={`${hour + "45"}`}
            value={getHourString(hour, 45, "HH:mm")}
          >
            {getHourString(hour, 45, "hh:mm a")}
          </option>
        );
      }
    }
    return hours;
  }
  function getHourString(hour, minute, format) {
    return moment({ hour: hour, minute: minute }).format(format);
  }

  const { day, active, start, end, lunch_start, lunch_end } = daySchedule;

  const [timeOptions, setTimeOptions] = useState([]);
  const [lunchTimeOptions, setLunchTimeOptions] = useState([]);

  useEffect(() => {
    setTimeOptions(generateTimeOptions());
  }, []);

  useEffect(() => {
    setLunchTimeOptions(generateLunchTimeOptions());
  }, [lunch_start]);

  return (
    <>
      <div
        className="error-container"
        style={{
          marginLeft: "470px",
          color: "red",
          display: "flex",
          flexDirection: "column"
        }}
      >
        {active && errors.appointment_config?.days?.[day]?.lunch_start && (
          <span>{errors.appointment_config.days[day].lunch_start}*</span>
        )}
        {active && errors.appointment_config?.days?.[day]?.start && (
          <span>{errors.appointment_config.days[day].start}*</span>
        )}
        {active && errors.appointment_config?.days?.[day]?.end && (
          <span>{errors.appointment_config.days[day].end}*</span>
        )}
        {active && errors.appointment_config?.days?.[day]?.lunch_end && (
          <span>{errors.appointment_config.days[day].lunch_end}*</span>
        )}
      </div>

      <div
        style={{
          display: "flex",
          flex: "35%",
          borderBottom: "black 1px solid",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "1em",
          width: "465px"
        }}
      >
        <FormikField
          name={`appointment_config.days.${day}.active`}
          type="checkbox"
          checked={active}
          styles={{ width: "500px" }}
          customHandleErrors={true}
        />

        <p style={{ flexBasis: "75px", margin: 0 }}>{day}</p>

        <div style={{ display: "flex", gap: "2em", alignItems: "center" }}>
          <FormikField
            name={`appointment_config.days.${day}.start`}
            values={start}
            disabled={!active}
            component="select"
            style={{ marginBottom: "-1rem", width: "75px" }}
            customHandleErrors={true}
          >
            {timeOptions.length > 0 && timeOptions}
          </FormikField>
          <FormikField
            name={`appointment_config.days.${day}.end`}
            values={end}
            disabled={!active}
            component="select"
            style={{ marginBottom: "-1rem", width: "75px" }}
            customHandleErrors={true}
          >
            {timeOptions.length > 0 && timeOptions}
          </FormikField>
        </div>
        <div style={{ display: "flex", gap: "2em", alignItems: "center" }}>
          <FormikField
            name={`appointment_config.days.${day}.lunch_start`}
            values={lunch_start}
            disabled={!active}
            inlineError={false}
            component="select"
            style={{ marginBottom: "-1rem", width: "75px" }}
            customHandleErrors={true}
            onChange={e => onLunchStartChanged(e.target.value)}
          >
            {timeOptions.length > 0 && timeOptions}
          </FormikField>
          <FormikField
            name={`appointment_config.days.${day}.lunch_end`}
            values={lunch_end}
            disabled={!active || !lunch_start}
            component="select"
            style={{ marginBottom: "-1rem", width: "75px" }}
            customHandleErrors={true}
          >
            {lunchTimeOptions.length > 0 && lunchTimeOptions}
          </FormikField>
        </div>
      </div>
    </>
  );
};

AvaiableDaysRow.propTypes = {
  daySchedule: PropTypes.shape({
    day: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    start: PropTypes.string,
    end: PropTypes.string,
    lunch_start: PropTypes.string,
    lunch_end: PropTypes.string
  }),
  errors: PropTypes.object,
  onLunchStartChanged: PropTypes.func
};
