import { message, errorMessage } from "actions/message";
import { get, post } from "utils/api";
import moment from "moment-timezone";

export const getCoachesAPI = async (dispatch, setGetting, setResult) => {
  try {
    setGetting(true);
    const result = await get(`/appointments/manager-view/coaches`);
    setResult(result);
    return true;
  } catch (err) {
    dispatch(
      errorMessage(
        err.response?.body?.message ?? err.message ?? "Failed to get coaches"
      )
    );
  } finally {
    setGetting(false);
  }
};

export const getCoachesByPatientAPI = async (
  dispatch,
  setGetting,
  setResult,
  patientId
) => {
  try {
    setGetting(true);
    const result = await get(
      `/appointments/manager-view/coaches/patient/${patientId}`
    );
    setResult(result);
    return true;
  } catch (err) {
    dispatch(
      errorMessage(
        err.response?.body?.message ?? err.message ?? "Failed to get coaches"
      )
    );
  } finally {
    setGetting(false);
  }
};

export const getUserAppointmentConfigAPI = async (
  dispatch,
  setGetting,
  setResult
) => {
  try {
    setGetting(true);
    const result = await get("/users/appointment_config");
    setResult(result);
    return true;
  } catch (err) {
    dispatch(
      errorMessage(
        err.response?.body?.message ??
          err.message ??
          "Failed to get appointment config"
      )
    );
  } finally {
    setGetting(false);
  }
};

export const getCoachAppointmentConfigAPI = async (
  dispatch,
  setGetting,
  setResult,
  coachId
) => {
  try {
    setGetting(true);
    const result = await get(`/users/${coachId}/appointment_config`);
    setResult(result);
    return true;
  } catch (err) {
    dispatch(
      errorMessage(
        err.response?.body?.message ??
          err.message ??
          "Failed to get appointment config"
      )
    );
  } finally {
    setGetting(false);
  }
};

export const createAppointmentAPI = async (
  dispatch,
  coachId,
  start_time,
  end_time,
  {
    title,
    description,
    max_number_of_invitees,
    service_ids,
    product_ids,
    chat_type,
    recurring,
    recurrenceEndDate,
    weekdays,
    appointment_data,
    confirmed
  }
) => {
  try {
    const patients = appointment_data.appointment_patient_ids.map(
      ({ user_id }) => {
        return user_id;
      }
    );
    const clean_appointment_data = {
      appointment_topic_id: appointment_data.appointment_topic_id,
      appointment_patient_ids: patients
    };
    const { success, message: responseMsg } = await post("/appointments", {
      start_time: moment(start_time).format("L LT"),
      end_time: moment(end_time).format("L LT"),
      title,
      description,
      max_number_of_invitees,
      service_ids,
      product_ids,
      chat_type,
      recurring,
      recurrenceEndDate,
      weekdays,
      appointment_data: clean_appointment_data,
      coach_id: coachId,
      confirmed: confirmed
    });
    if (success) dispatch(message(responseMsg));
    else dispatch(errorMessage(responseMsg));
    return true;
  } catch (err) {
    dispatch(
      errorMessage(
        err.response?.body?.message ?? "Failed to create appointment"
      )
    );
    return false;
  }
};

export const getTopicCategoriesAPI = async (
  dispatch,
  setGettingTopicCategories,
  setTopicCategories
) => {
  try {
    setGettingTopicCategories(true);
    const result = await get(`dme_portal/appointment_topic_categories`);
    if (result.success) {
      setTopicCategories(result.topic_categories);
    } else {
      dispatch(
        errorMessage(result.message ?? "Failed to get topic categories")
      );
    }
    return true;
  } catch (err) {
    dispatch(
      errorMessage(
        err.response?.body?.message ??
          err.message ??
          "Failed to get topic categories"
      )
    );
  } finally {
    setGettingTopicCategories(false);
  }
};
