import React, { useState } from "react";
import { convertDatesToGridSpan } from "../../../availability-scheduler/scheduler-helpers";
import Calandar from "../../../availability-scheduler/calendar";
import { selectors } from "reducers";
import ScheduledSlot from '../../../availability-scheduler/scheduled-slot';
import { CreateEditViewTimeSlotModal } from '../../../availability-scheduler/create-time-slot-modal';
import TempScheduledSlot from '../../../availability-scheduler/temp-scheduled-slot';
import { useSelector } from "react-redux";
import TimeSlots from "../../../availability-scheduler/time-slots";
import { TimeSlotContext } from "../../../availability-scheduler/use-availabilityScheduler";
import { Spinner } from "components/ui";

const ViewByWeek = () => {
  const user = useSelector(selectors.getUser);
  const [willEditTimeSlot] = useState(false)

  const {
    setEditSlot, pendingTimeSlot, editSlot, week,
    coachViewAppointments, coachViewAvailability,
    gettingCoachViewAppointments, creatingOrEditingTimeSlots
  } = React.useContext(TimeSlotContext);

  return (
    <div className="calendar-container">
      <Calandar
        willEditTimeSlot={willEditTimeSlot}
        timeZone={user.timezone}
        isCoachView={true}
      >
        {<TimeSlots week={week} timeZone={user.timezone} coachAvailability={coachViewAvailability} />}
        {pendingTimeSlot ? (
          <TempScheduledSlot
            {...convertDatesToGridSpan(pendingTimeSlot)}
            setEditSlot={setEditSlot}
            willEditTimeSlot={willEditTimeSlot}
            timeZone={user.timezone}
          />
        ) : null}
        {coachViewAppointments?.length > 0 &&
          coachViewAppointments?.map(a => convertDatesToGridSpan(a))
            .map(slot => <ScheduledSlot key={slot.slot_id} slot={slot} />)
        }
      </Calandar>
      {editSlot && !pendingTimeSlot && (
        <CreateEditViewTimeSlotModal timeZone={user.timezone} data={editSlot} isViewing={true} />
      )}
      {
        (gettingCoachViewAppointments || creatingOrEditingTimeSlots) &&
        <div className="calendar-spinner-container">
          <Spinner />
        </div>
      }
    </div>
  )

}
export default ViewByWeek