import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import DayPicker from "react-day-picker";
import IoMdMinus from "react-icons/lib/io/minus";
import IoMdClose from "react-icons/lib/io/close";
import IoMdCalender from "react-icons/lib/io/calendar";
import CalenderControls from "./calender-controls";

const DatePicker = ({
  css,
  clear,
  containerCss,
  disabled,
  showControls,
  onChange,
  selectedDay,
  managerView,
  showByDefault,
  hideIcon,
  keepOpen,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(showByDefault || false);
  const [activeDay, setActiveDay] = useState(selectedDay ? new Date(selectedDay) : null);

  const divRef = useRef(null);
  const [position, setPosition] = useState({ left: 0 });



  const dayStyle = (day) => {
    if (keepOpen) {
      return {
        display: 'inline-block',
        width: '100%',
        height: '100%',
        padding: '0.5em',
        borderRadius: '50%',
        backgroundColor: activeDay && activeDay.toDateString() === day.toDateString() ? '#3498db' : 'transparent',
        color: activeDay && activeDay.toDateString() === day.toDateString() ? 'white' : 'inherit',
      };
    } else {
      return {};
    }
  };


  const getPosition = () => {
    if (divRef.current) {
      const rect = divRef.current.getBoundingClientRect();
      setPosition({
        left: rect.left + window.scrollX
      });
    }
  };

  return (
    <div style={css} className="date-picker container" ref={divRef}>
      {clear && !managerView && (
        <i
          onClick={e => {
            e.stopPropagation();
            if (!disabled) onChange("");
          }}
          className="icon clear"
          title="clear"
        >
          <IoMdMinus />
        </i>
      )}
      {!hideIcon && (!disabled ? (
        <i
          onClick={() => {
            if (!isActive) getPosition()
            setIsActive(!isActive);
          }}
          className={managerView ? "icon-black" : "icon"}
          title="set date"
        >
          {isActive ? <IoMdClose /> : <IoMdCalender />}
        </i>
      ) : (
        <i>
          <IoMdCalender color="#ddd" />
        </i>
      ))}
      {isActive && (
        <div id={"datePicker"} className="entryContainer"
          style={managerView ? { position: "fixed", left: `${position.left}px`, zIndex: 100 } : {}}
        >

          {!keepOpen && (
            <div className="outOfBounds" onClick={() => setIsActive(false)} />
          )}

          <div className="pickerContainer" style={containerCss}>
            <DayPicker
              {...rest}
              onDayClick={day => {
                if (!keepOpen) {
                  setIsActive(false);
                }
                else {
                  setActiveDay(day);
                }
                onChange(day);
              }}
              containerProps={{ style: { padding: 0 } }}
              selectedDays={[selectedDay ? new Date(selectedDay) : selectedDay]}
              renderDay={day => (
                <div style={dayStyle(day)}>
                  {day.getDate()}
                </div>
              )}
            />
            {showControls && (
              <CalenderControls
                handleDayChange={day => {
                  onChange(day);
                  setIsActive(false);
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

DatePicker.defaultProps = {
  css: {},
  containerCss: {},
  disabled: false,
  clear: true,
  showControls: false,
  showByDefault: false,
  hideIcon: false,
  keepOpen: false,
};

DatePicker.propTypes = {
  onChange: PropTypes.func,
  css: PropTypes.object,
  containerCss: PropTypes.object,
  clear: PropTypes.bool,
  disabled: PropTypes.bool,
  showControls: PropTypes.bool,
  selectedDay: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ]),
  showByDefault: PropTypes.bool,
  hideIcon: PropTypes.bool,
  keepOpen: PropTypes.bool,
  managerView: PropTypes.bool
};

export default DatePicker;
