import React, {useEffect} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getUpcomingAppointments } from "components/dd_newModules/Redux/Actions/schedule";
import UpcomingAppointment from "./upcoming-appointment.js";
import "./upcoming-chats.scss";
import { Spinner } from "components/ui";

const UpcomingAppointments = ({ getUpcomingAppointments, upcomingAppointmentsList, isLoading, timeZone, resetTimeZoneValue}) => {
  React.useEffect(() => {
    getUpcomingAppointments();
  }, []);

  useEffect(() => {   
    if(timeZone){
        getUpcomingAppointments();
        resetTimeZoneValue();
    }
  }, [timeZone]);

  return !isLoading ? (
    <div className="upcoming-chats">
      {upcomingAppointmentsList?.length == 0 ? (
        <div className="no-upcoming-chats-msg">
          You Have No Upcoming Appointments
        </div>
      ) : (
        <div className="upcoming-chats-container">
          {upcomingAppointmentsList?.map(data => {
              return (
                  <UpcomingAppointment key={data.appointmentId} appointment={data} getUpcomingAppointments={getUpcomingAppointments} />
              )
          })}
        </div>
      )}
    </div>
  ) : (
    <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
       <Spinner/> 
    </div>
  );
};

UpcomingAppointments.propTypes = {
  getUpcomingAppointments: PropTypes.func.isRequired,
  upcomingAppointmentsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  timeZone: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  resetTimeZoneValue: PropTypes.func
};

export default connect(
  state => ({
    upcomingAppointmentsList: state.getIn(["newRootReducer"]).rdc_schedule.UpcomingAppointments,
    isLoading: state.getIn(["newRootReducer"]).rdc_loader.isLoader
  }),
  { getUpcomingAppointments }
)(UpcomingAppointments);
