import React from "react";
import PropTypes from "prop-types";
import useLocalQuery, { Table, NonSortableHeader } from "hooks/useQuery/local-query";
import { formatDate } from "utils/dates";
import './style.scss'
import { useDispatch } from "react-redux";
import { errorMessage } from "actions/message";
import { MdAddCircle } from "react-icons/lib/md";
const PatientSearchResults = ({
  multiple,
  patients,
  isFetching,
  selectedPatients,
  setSelectedPatients,
  maxInvitees,
  alreadyAssignedPatients,
  isManagerView
}) => {
  const { rows, query, queryDispatcher } = useLocalQuery(patients, {});
  const dispatch = useDispatch()

  return (
    <Table
      isFetching={isFetching}
      containerClass="query-table patient-search-results-table-for-modal"
      scrollable
      TableRow={({
        patient_name,
        email,
        company_name,
        date_of_birth,
        account_number,
        user_id,
        primary_phone_number
      }) => (
        <tr
          onMouseDown={() => {
            const maxInvitessErrMessage = maxInvitees == 0
              ? "You must select a Chat Type"
              : `This chat type doesn’t allow more than ${maxInvitees} patients`

            if (multiple) {
              if (alreadyAssignedPatients && !isManagerView) {
                if (selectedPatients.some(element => element.user_id == user_id) || alreadyAssignedPatients?.some(element => element.patient_id == user_id)) {
                  dispatch(errorMessage("Patient already assigned"))
                } else {
                  if ((selectedPatients.length + alreadyAssignedPatients.length) < maxInvitees) {
                    setSelectedPatients([...selectedPatients, { user_id, patient_name }]);
                  } else {
                    dispatch(errorMessage(maxInvitessErrMessage))
                  }
                }
              } else {
                if (selectedPatients.some(element => element.user_id == user_id)) {
                  dispatch(errorMessage("Patient already assigned"))
                } else {
                  if (selectedPatients.length < maxInvitees) {
                    setSelectedPatients([...selectedPatients, { user_id, patient_name }]);
                  } else {
                    dispatch(errorMessage(maxInvitessErrMessage))
                  }
                }
              }
            } else {
              if (selectedPatients === user_id) {
                setSelectedPatients("");
              } else {
                setSelectedPatients(user_id);
              }
            }
          }}
        >
          <td>
            <MdAddCircle size="20px" color="#356785" />
          </td>
          <td>{patient_name}</td>
          <td
            style={{
              minWidth: "120px",
              overflowWrap: "anywhere"
            }}
          >
            {email}
          </td>
          <td>{formatDate(date_of_birth)}</td>
          <td>{account_number}</td>
          <td>{primary_phone_number}</td>
          <td>{company_name}</td>
        </tr>
      )}
      columns={[
        {
          header: "",
          field: "controls",
          Cell: () => <NonSortableHeader text="" style={{ width: "25px" }} />
        },
        {
          header: "Name",
          field: "patient_name",
          Cell: () => <NonSortableHeader text="Name" />
        },
        {
          header: "Email",
          field: "patient_email",
          Cell: () => (
            <NonSortableHeader text="Email" style={{ width: "170px" }} />
          )
        },
        {
          header: "Date Of Birth",
          field: "date_of_birth",
          Cell: () => <NonSortableHeader text="Date Of Birth" style={{ width: "100px" }} />
        },
        {
          header: "Account Number",
          field: "account_number",
          Cell: () => <NonSortableHeader text="Account Number" style={{ width: "100px" }} cellStyle={{ width: "unset" }} />
        },
        {
          header: "Primary Phone Number",
          field: "primary_phone_number",
          Cell: () => <NonSortableHeader text="Primary Phone Number" />
        },
        {
          header: "Company",
          field: "company_name",
          Cell: () => <NonSortableHeader text="Company" />
        }
      ]}
      query={query}
      queryDispatcher={queryDispatcher}
      rows={rows}
      rowKey="user_id"
    />
  );
};

export default PatientSearchResults;

PatientSearchResults.propTypes = {
  patients: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool,
  selectedPatients: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  setSelectedPatients: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  maxInvitees: PropTypes.number,
  alreadyAssignedPatients: PropTypes.arrayOf(PropTypes.object),
  isManagerView: PropTypes.bool
};