import React from "react";
import { PureModal } from "components/ui";
import ScheduleAppointmentModal from "./schedule-appointment-modal";
import PropTypes from "prop-types";

export default function ScheduleAppointmentFromProfile({
  isButtonTrigger = false,
  patientId,
  userId
}) {
  return (
    <div
      style={{
        gridArea: "2",
        alignContent: "center",
        justifyContent: "end",
        display: "flex"
      }}
    >
      {isButtonTrigger ? (
        <PureModal
          windowProps={{ closeable: false }}
          maxWidth="sm"
          renderTrigger={({ openModal }) => (
            <span
              onClick={() => openModal()}
              style={{
                color: "blue",
                cursor: "pointer"
              }}
            >
              + New Appointment
            </span>
          )}
          renderContent={({ closeModal }) => (
            <div style={{ paddingLeft: "10px" }}>
              <ScheduleAppointmentModal
                onClose={closeModal}
                patientId={patientId}
                userId={userId}
              />
            </div>
          )}
        ></PureModal>
      ) : (
        <PureModal
          windowProps={{ closeable: false }}
          maxWidth="sm"
          startOpen={true}
          renderTrigger={() => <span />}
          renderContent={({ closeModal }) => (
            <div style={{ paddingLeft: "10px" }}>
              <ScheduleAppointmentModal
                onClose={closeModal}
                patientId={patientId}
              />
            </div>
          )}
        ></PureModal>
      )}
    </div>
  );
}

ScheduleAppointmentFromProfile.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isButtonTrigger: PropTypes.bool,
  patientId: PropTypes.string,
  userId: PropTypes.string,
};
