import React from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import CompanyTable from './table'
import { PageHeader } from 'components/ui'
import { selectors } from 'reducers'

const AllCompanyTable = ({ showNewCompanyButton }) => (
  <div>
    <PageHeader
      title='Companies'
      button={showNewCompanyButton
        ? { path: '/companies/new', value: 'New Company' }
        : undefined} />
    <CompanyTable />
  </div>
)

export default connect(state => {
  const role = selectors.getUserRole(state)
  return {
    showNewCompanyButton: role === 'Administrator'
  }
})(AllCompanyTable)

AllCompanyTable.propTypes = {
  showNewCompanyButton: PropTypes.bool
};
