import React, { useState } from "react";
import { PureModal, Spinner, ConfirmationDialog } from "components/ui";
import { useDispatch } from "react-redux";
import AppointmentSearch from "../assign-chat/appointment-search";
import { useFetch } from "hooks";
import { put } from "utils/api";
import { errorMessage, message } from "actions/message";
import { PropTypes } from 'prop-types';
import { getApiErrorMessageCustomPathEnd } from "utils/misc";
import moment from "moment";

const RescheduleAppointmentModal = ({ appointmentId, timeRange, onSuccess, closeModal })=> {
    const [displaySpinner, setDisplaySpinner] = useState(false);
    const [newStartDate, setNewStartDate] = useState(null)
    const dispatch = useDispatch()
    const userLoggedIn = JSON.parse(window.localStorage.getItem("evergreen"));

    function closeRescheduleModal(){
        document.getElementById("closeRescheduleModalButton").click();
    }

    const {
        fetchData : rescheduleChat
    } = useFetch({
        apiFn: values => put(`appointments/${appointmentId}/reschedule`, values),
        defaultValue: [],
        onError: error => {
            dispatch(errorMessage(`Failed to reschedule: ${getApiErrorMessageCustomPathEnd(error, "message")}`))
        },
        onSuccess: () => {
            dispatch(message(`Rescheduled successfully`))
            closeRescheduleModal()
            onSuccess()
        }
    });

    const submit = async () => {
        setDisplaySpinner(true);
        const originalDateStart = moment(timeRange.Start).tz(userLoggedIn.timezone)
        const originalDateEnd = moment(timeRange.End).tz(userLoggedIn.timezone)
        var diff = originalDateEnd.diff(originalDateStart, 'minutes');

        await rescheduleChat({Start: newStartDate.format('LLL'), End: newStartDate.add(diff, 'minutes').format('LLL')})
        setDisplaySpinner(false);
    }

    return(
        <div style={{padding: "10px"}}>
            <AppointmentSearch originalDate={timeRange.Start} setNewStartDate={setNewStartDate}/>
            <button id="closeRescheduleModalButton" style={{display: "none"}} onClick={() => closeModal()}></button>
                <PureModal
                    maxWidth="sm"
                    fullWidth
                    renderTrigger={({ openModal }) => (
                            <div style={{display: "flex", justifyContent: "center"}}>
                                {
                                    displaySpinner ?
                                    <Spinner />
                                    :
                                    <button
                                        id="rescheduleConfirmationModal"
                                        className="appointment-action-button reschedule-button"
                                        style={{ marginLeft: 10 }}
                                        onClick={() => {openModal()}}
                                        disabled={!newStartDate || newStartDate === ""}
                                    >
                                        Confirm Time Slot
                                    </button>
                                }
                            </div>
                        )
                    }
                    renderContent={({ closeModal }) => (
                        <div>
                            <ConfirmationDialog
                                cancelButtonStyles="appointment-action-button cancel-action-button"
                                confirmButtonStyles="appointment-action-button reschedule-button"
                                continueMsg="Yes"
                                onCancel={closeModal}
                                onConfirm={() => {closeModal() ;submit(closeModal)}}>
                                <h3>Are you sure you want to reschedule the appointment?</h3>
                            </ConfirmationDialog>
                        </div>
                    )}
                />
        </div>
    )

}

RescheduleAppointmentModal.propTypes = {
    appointmentId: PropTypes.number,
    timeRange: PropTypes.object,
    onSuccess: PropTypes.func,
    closeModal: PropTypes.func,
};

export default RescheduleAppointmentModal;
