import { GET_TIMESLOTS, GET_HISTORY, GET_UPCOMING_CHAT, GET_UPCOMING_APPOINTMENTS, SET_SLOT, GET_APPOPINTMENT_TOPIC } from './../ActionTypes';

const initialState = {
    TimeSlots: [],
    isTimeSlotCreated: false,
    History: [],
    UpcomingChat: [],
    UpcomingAppointments: [],
    AppointmentTopicCategories: []
}

const reducerSchedule = (state = initialState, action) => {
    switch (action.type) {
        case GET_TIMESLOTS:
            return {
                ...state,
                TimeSlots: action.payload
            };
        case GET_HISTORY:
            return {
                ...state,
                History: action.payload
            };
        case GET_UPCOMING_CHAT:
            return {
                ...state,
                UpcomingChat: action.payload
            };
        case GET_UPCOMING_APPOINTMENTS:
            return {
                ...state,
                UpcomingAppointments: action.payload
            };
        case SET_SLOT:
            return {
                ...state,
                isTimeSlotCreated: action.payload
            };
            case GET_APPOPINTMENT_TOPIC:
                return {
                    ...state,
                    AppointmentTopicCategories: action.payload
                };
        default:
            return state;
    }
}

export default reducerSchedule;
