import React from "react";
import PropTypes from "prop-types";
import useMousePosition from "../../../availability-scheduler/use-mouse-position"
import { formatTimeFromHour } from "utils/dates";
import { convertDatesToGridSpan, managerViewGridPosTodate, convertGridSpanToDatesByDay,
  isInvalidSlot,
  isBefore} from "../../../availability-scheduler/scheduler-helpers";
import { TimeSlotContext } from "../../../availability-scheduler/use-availabilityScheduler";

const Times = Array.from(Array(24).keys()).filter(t => t >= 5 && t <= 19);
const CellHeight = 15;
const CellWidth = 180;
const getRow = y => Math.floor(y / CellHeight);
const getCol = x => Math.floor(x / CellWidth);

const CalendarByDay = React.memo(({ children, willEditTimeSlot, timeZone }) => {
  const [mousePosition, schedulerRef] = useMousePosition();
  const {
    setSelected,
    editSlot,
    setEditSlot,
    selected,
    coachViewAppointments,
    setPendingTimeSlot,
    date,
  } = React.useContext(TimeSlotContext);

  return (
    <div className="calendar-by-day" style={{position: "relative"}}>
      <div/>
      
      <div className="times">
        {Times.map(t => (
          <div key={t} style={{ gridRow: t - 4 }}>
            {formatTimeFromHour(t)}
          </div>
        ))}
      </div>
      <div
        ref={schedulerRef}
        className="time-slots-day-container"
        onClick={e => {
          e.preventDefault();
        }}
        onMouseLeave={() => {
          setSelected({
            row1: null,
            col: null
          });
        }}
        onMouseDown={() => {
            const row1 = getRow(mousePosition.y);
            const col = getCol(mousePosition.x);
            const selectedStartTime = managerViewGridPosTodate(row1, date, timeZone);
            if (!isBefore(selectedStartTime)) {
              setSelected(() => ({
                row1,
                col
              }));
            }
            if (editSlot && !willEditTimeSlot) setEditSlot();
        }}
        onMouseUp={() => {
          if (selected.col !== null && selected.row1 !== null) {
            const row2 = getRow(mousePosition.y + CellHeight);
            const startRow = selected.row1 <= row2 ? selected.row1 : row2 - 1;
            const endRow = row2 >= selected.row1 ? row2 : selected.row1 + 1;
            const overlappingAppointment = coachViewAppointments?.length > 0 && coachViewAppointments
              .map(convertDatesToGridSpan)
              .filter(({ col }) => col === selected.col)
              .find(slot =>
                isInvalidSlot(
                  { row1: slot.row1, row2: slot.row2 },
                  { row1: startRow, row2: endRow }
                )
              );
            
         
            const newTimeSlot = {
              ...convertGridSpanToDatesByDay({
                row1: startRow,
                row2: endRow,
                day: date,
              })
            };
            if (!overlappingAppointment && !isBefore(newTimeSlot.startTime)) {
              setPendingTimeSlot(newTimeSlot);
            }
            setSelected({
              row1: null,
              col: null
            });
          }
        }}
      >
        {children}
      </div>
    </div>
  );
});

CalendarByDay.propTypes = {
    children: PropTypes.node,
    willEditTimeSlot: PropTypes.bool,
    timeZone: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
};

export default CalendarByDay;
