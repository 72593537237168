import React from "react";
import { useDispatch } from "react-redux";
import {
  getCoachesByPatientAPI,
  getUserAppointmentConfigAPI,
  getCoachAppointmentConfigAPI,
  createAppointmentAPI,
  getTopicCategoriesAPI
} from "../../../screens/chats/scheduler-actions/scheduler-api-actions";

const usePatientProfileAppointmentsContext = () => {
  const dispatch = useDispatch();
  const [coachAppointmentConfig, setCoachAppointmentConfig] =
    React.useState(null);
  const [gettingCoachAppointmentConfig, setGettingCoachAppointmentConfig] =
    React.useState(false);
  const [userAppointmentConfig, setUserAppointmentConfig] = React.useState([]);
  const [gettingUserAppointmentConfig, setGettingUserAppointmentConfig] =
    React.useState(false);
  const [coachesByPatient, setCoachesByPatient] = React.useState([]);
  const [gettingCoachesByPatient, setGettingCoachesByPatient] =
    React.useState(false);
  const [selectedCoach, setSelectedCoach] = React.useState("");
  const [topicCategories, setTopicCategories] = React.useState([]);
  const [gettingTopicCategories, setGettingTopicCategories] =
    React.useState(false);

  React.useEffect(() => {
    getUserAppointmentConfigAPI(
      dispatch,
      setGettingUserAppointmentConfig,
      setUserAppointmentConfig
    );
    getTopicCategoriesAPI(
      dispatch,
      setGettingTopicCategories,
      setTopicCategories
    );
  }, []);

  React.useEffect(() => {
    if (selectedCoach) {
      getCoachAppointmentConfigAPI(
        dispatch,
        setGettingCoachAppointmentConfig,
        setCoachAppointmentConfig,
        selectedCoach
      );
    }
  }, [selectedCoach]);

  const createAppointment = async (coachId, start_time, end_time, options) => {
    return await createAppointmentAPI(
      dispatch,
      coachId,
      start_time,
      end_time,
      options
    );
  };

  const getCoachesByPatient = async patientId => {
    await getCoachesByPatientAPI(
      dispatch,
      setGettingCoachesByPatient,
      setCoachesByPatient,
      patientId
    );
  };

  return {
    coachAppointmentConfig,
    userAppointmentConfig,
    gettingUserAppointmentConfig,
    gettingCoachAppointmentConfig,
    coachesByPatient,
    gettingCoachesByPatient,
    selectedCoach,
    topicCategories,
    gettingTopicCategories,
    setSelectedCoach,
    createAppointment,
    getCoachesByPatient
  };
};

export default usePatientProfileAppointmentsContext;

export const AppointmentsContext = React.createContext();
