import React, { useEffect } from "react";
import { convertDatesToGridSpan } from "../scheduler-helpers";
import useAvailabilitySchedulerDay, {
  TimeSlotContextDay
} from "./use-availabilitySchedulerDay";
import CalendarDay from "./calendarDay";
import PropTypes from "prop-types";
import TimeSlotsDay from "./time-slots-day";
import ScheduledSlotDay from "./scheduled-slot-day";
import moment from "moment";
import './reschedule-by-day.scss'

const AvailabilitySchedulerDay = React.memo(({ originalDate, setNewStartDate }) => {
  const userLoggedIn = JSON.parse(window.localStorage.getItem("evergreen"));
  const [selectedDate, setSelectedDate] = React.useState(moment(originalDate).tz(userLoggedIn.timezone).format("YYYY-MM-DD"));
  const today = moment().format("YYYY-MM-DD")
  const {
    userTimeSlots,
    userAvailability,
    getUserTimeSlots,
    getUserAvailability,
    selected,
    setSelected,
    gettingTimeSlots,
    creatingOrEditingTimeSlots,
    setCreatingOrEditingTimeSlots,
  } = useAvailabilitySchedulerDay();

  useEffect(() => {
    getUserTimeSlots(selectedDate);
    getUserAvailability();
  }, [selectedDate]);

  return (
    <TimeSlotContextDay.Provider
      value={{
        userTimeSlots,
        getUserTimeSlots,
        selected,
        setSelected,
        gettingTimeSlots,
        creatingOrEditingTimeSlots,
        setCreatingOrEditingTimeSlots
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "center"
        }}
      >
        <div className="form-field">
          <div className="form-field-header">Select Date</div>
          <input
            type="date"
            value={selectedDate}
            onChange={({ target }) => {
              setSelectedDate(target.value)
              setSelected(() => ({
                row1: null,
                col: null
              }));
            }
            }
            min={today}
            className="input-field"
          />
        </div>
      </div>

      <div className="rescheduler">
        <div className="calendar-container">
          <CalendarDay
            timeZone={userLoggedIn.timezone}
            selectedDate={selectedDate}
            setNewStartDate={setNewStartDate}
          >
            <TimeSlotsDay
              selectedDate={selectedDate}
              timeZone={userLoggedIn.timezone}
              coachAvailability={userAvailability}
            />

            {userTimeSlots
              .map(s => ({ ...s, ...convertDatesToGridSpan(s) }))
              .map(slot => (
                <ScheduledSlotDay key={slot.AppointmentId} slot={slot} />
              ))}
          </CalendarDay>
        </div>
      </div>
    </TimeSlotContextDay.Provider>
  );
});

AvailabilitySchedulerDay.propTypes = {
  setNewStartDate: PropTypes.func.isRequired,
  originalDate: PropTypes.string.isRequired,
}

export default AvailabilitySchedulerDay;
