import React from "react";
import { TimeSlotContext } from "../../../availability-scheduler/use-availabilityScheduler"

const ViewOptions = React.memo(() => {
    const { coachViewView, setCoachViewView, gettingCoachViewAppointments } = React.useContext(TimeSlotContext);

    const activeStyle = { marginRight: '10px', backgroundColor: 'grey' };
    const inactiveStyle = { marginRight: '10px', color: 'black', backgroundColor: '#F5F5F5' };

    return (
        <div style={{ display: 'flex', margin: "15px 50px 0 50px" }}>
            <button onClick={() => setCoachViewView("day")} style={coachViewView == "day" ? activeStyle : inactiveStyle} disabled={gettingCoachViewAppointments}>
                Day
            </button>
            <button onClick={() => setCoachViewView("week")} style={coachViewView == "week" ? activeStyle : inactiveStyle} disabled={gettingCoachViewAppointments}>
                Week
            </button>
            <button onClick={() => setCoachViewView("month")} style={coachViewView == "month" ? activeStyle : inactiveStyle} disabled={gettingCoachViewAppointments}>
                Month
            </button>
        </div>
    );
});

export default ViewOptions;
