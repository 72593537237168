import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectors } from "reducers";
import CalandarLegend from "../availability-scheduler/calendar-legend";
import CoachCalendar from "./coach-calendar";
import TimeSlots from "./manager-view-time-slots";
import useAvailabilityScheduler, {
  TimeSlotContext
} from "../availability-scheduler/use-availabilityScheduler";
import DayPicker from "./day-picker";
import DaySelect from "./day-select";
import { managerViewConvertDatesToGridSpan } from "../availability-scheduler/scheduler-helpers";
import ScheduledSlot from "../availability-scheduler/scheduled-slot";
import { CreateEditViewTimeSlotModal } from "../availability-scheduler/create-time-slot-modal";
import TempScheduledSlot from "../availability-scheduler/temp-scheduled-slot";
import ViewOptions from "./view-options";
import CoachTimeline from "./coach-timeline";
import CoachView from "./coach-view/index";
import "./timeline.scss";
import CreateTimeOffForCoach from "../create-timeoff-for-coach/index";
import SearchAppointments from "./search-appointments/index";
import { Spinner } from "components/ui";

export default function ManagerView({ timeZone, resetTimeZoneValue }) {
  const user = useSelector(selectors.getUser);

  const {
    selectDate,
    date,
    setSelected,
    selected,
    getManagerViewAppointments,
    managerAppointments,
    managerViewCoaches,
    setPendingTimeSlot,
    setEditSlot,
    pendingTimeSlot,
    editSlot,
    editAppointmentById,
    createAppointment,
    setCreatingOrEditingTimeSlots,
    isManagerView,
    getManagerViewCoachAppointmentConfig,
    setIsManagerView,
    managerViewCoachAppointmentConfig,
    gettingTimeSlots,
    setGettingTimeSlots,
    fetchingManagerView,
    getManagerViewCoachAvailability,
    creatingOrEditingTimeSlots,
    isBetweenWorkingTime,
    managerViewView,
    setManagerViewView,

    coachViewCoachID,
    setCoachViewCoachID,
    setCoachViewView,
    coachViewView,
    coachViewAppointments,
    coachViewAppointmentsMonth,
    coachViewAvailabilityMonth,
    gettingCoachViewAppointments,
    gettingCoachViewAppointmentsForMonth,
    getCoachViewAvailability,
    getCoachViewAppointments,
    week,
    selectWeek,
    coachViewAvailability,
    year,
    selectYear,
    month,
    selectMonth,
    managerViewEditAppointment,
    managerViewCancelAppointment,

    coaches,
    gettingCoaches,
    topicCategories,
    gettingTopicCategories,
    patients,
    gettingPatients,
    getCoachViewAppointmentsMonth
  } = useAvailabilityScheduler();

  React.useEffect(() => {
    setIsManagerView(true);
    getManagerViewAppointments();
  }, []);

  useEffect(() => {
    if (pendingTimeSlot && pendingTimeSlot.coachId)
      getManagerViewCoachAppointmentConfig(pendingTimeSlot.coachId);
  }, [pendingTimeSlot]);

  return (
    <TimeSlotContext.Provider
      value={{
        date,
        selectDate,
        selected,
        setSelected,
        getManagerViewAppointments,
        managerAppointments,
        managerViewCoaches,
        setPendingTimeSlot,
        setEditSlot,
        pendingTimeSlot,
        editSlot,
        editAppointmentById,
        createAppointment,
        setCreatingOrEditingTimeSlots,
        isManagerView,
        getManagerViewCoachAppointmentConfig,
        setIsManagerView,
        managerViewCoachAppointmentConfig,
        gettingTimeSlots,
        setGettingTimeSlots,
        fetchingManagerView,
        getManagerViewCoachAvailability,
        creatingOrEditingTimeSlots,
        isBetweenWorkingTime,
        managerViewView,
        setManagerViewView,

        coachViewCoachID,
        setCoachViewCoachID,
        coachViewView,
        setCoachViewView,
        coachViewAppointments,
        coachViewAppointmentsMonth,
        coachViewAvailabilityMonth,
        gettingCoachViewAppointments,
        gettingCoachViewAppointmentsForMonth,
        getCoachViewAvailability,
        week,
        selectWeek,
        year,
        selectYear,
        month,
        selectMonth,
        getCoachViewAppointments,
        coachViewAvailability,
        managerViewEditAppointment,
        managerViewCancelAppointment,

        coaches,
        gettingCoaches,
        topicCategories,
        gettingTopicCategories,
        patients,
        gettingPatients,
        getCoachViewAppointmentsMonth
      }}
    >
      <div className="scheduler">
        {coachViewCoachID && (
          <CoachView
            timeZone={timeZone}
            resetTimeZoneValue={() => resetTimeZoneValue()}
          />
        )}
        {!coachViewCoachID && (
          <div className="calendar-wrapper">
            <div className="calendar-header">
              <DaySelect />
              <DayPicker />
              <SearchAppointments />
              <CalandarLegend />
              <CreateTimeOffForCoach isButtonTrigger />
              <ViewOptions />
            </div>
            {managerViewView == "day" && (
              <div className="calendar-container">
                <CoachCalendar willEditTimeSlot={true} timeZone={user.timezone}>
                  <TimeSlots timeZone={user.timezone} />
                  {pendingTimeSlot ? (
                    <TempScheduledSlot
                      {...managerViewConvertDatesToGridSpan(
                        managerViewCoaches,
                        pendingTimeSlot
                      )}
                      setEditSlot={setEditSlot}
                      willEditTimeSlot={true}
                      timeZone={user.timezone}
                    />
                  ) : null}
                  {managerAppointments.length > 0 &&
                    managerAppointments
                      .map(s => ({
                        ...s,
                        ...managerViewConvertDatesToGridSpan(
                          managerViewCoaches,
                          s
                        )
                      }))
                      .map(appointment => (
                        <ScheduledSlot
                          key={`${appointment.IsTimeOff ? "timeoff-" : ""}${appointment.AppointmentId
                            }`}
                          slot={appointment}
                        />
                      ))}
                </CoachCalendar>
                {editSlot && !pendingTimeSlot && (
                  <CreateEditViewTimeSlotModal
                    timeZone={user.timezone}
                    data={editSlot}
                    isViewing={true}
                    managerView={true}
                  />
                )}
                {(fetchingManagerView ||
                  gettingTimeSlots ||
                  creatingOrEditingTimeSlots) && (
                    <div className="calendar-spinner-container">
                      <Spinner />
                    </div>
                  )}
              </div>
            )}
            {managerViewView == "timeline" && (
              <div className="timeline-container">
                <CoachTimeline
                  willEditTimeSlot={false}
                  timeZone={user.timezone}
                >
                  <TimeSlots timeZone={user.timezone} isTimeline={true} />
                  {pendingTimeSlot ? (
                    <TempScheduledSlot
                      {...managerViewConvertDatesToGridSpan(
                        managerViewCoaches,
                        pendingTimeSlot
                      )}
                      setEditSlot={setEditSlot}
                      willEditTimeSlot={false}
                      timeZone={user.timezone}
                      isTimeline={true}
                    />
                  ) : null}
                  {managerAppointments.length > 0 &&
                    managerAppointments
                      .map(s => ({
                        ...s,
                        ...managerViewConvertDatesToGridSpan(
                          managerViewCoaches,
                          s
                        )
                      }))
                      .map(appointment => (
                        <ScheduledSlot
                          isTimeline={true}
                          key={appointment.AppointmentId}
                          slot={appointment}
                        />
                      ))}
                </CoachTimeline>
                {editSlot && !editSlot.IsTimeOff && !pendingTimeSlot && (
                  <CreateEditViewTimeSlotModal
                    timeZone={user.timezone}
                    data={editSlot}
                    isViewing={true}
                    managerView={true}
                  />
                )}
                {(fetchingManagerView ||
                  gettingTimeSlots ||
                  creatingOrEditingTimeSlots) && (
                    <div className="calendar-spinner-container">
                      <Spinner />
                    </div>
                  )}
              </div>
            )}
          </div>
        )}
        {editSlot && editSlot.IsTimeOff && <CreateTimeOffForCoach />}
      </div>
    </TimeSlotContext.Provider>
  );
}

ManagerView.propTypes = {
  timeZone: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  resetTimeZoneValue: PropTypes.func
};
