import React, { useState } from "react";
import PropTypes from "prop-types";
import { Spinner } from "components/ui";
import "./appointment-actions.scss";
import CardCancelAppointmentButton from "../cancel-appointment/card-button-open-modal.jsx";
import CardRescheduleAppointmentButton from "../reschedule-appointment/card-button-open-modal";
import { useFetch } from "hooks";
import { put } from "utils/api";
import { useDispatch } from "react-redux";
import { message, errorMessage } from 'actions/message';
import { getApiErrorMessageCustomPathEnd } from "utils/misc";

const AppointmentActions = ({
    start_time,
    end_time,
    meeting_status,
    getUpcomingAppointments,
    createdByPatient,
    appointmentId,
    can_reschedule,
    patientsToBeNotified,
}) => {
    const dispatch = useDispatch()
    const rescheduleEnabled = meeting_status === "not completed" || meeting_status === "rescheduled";
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        fetchData: fetchAcceptOrDeclineAppointment
    } = useFetch({
        apiFn: (status) => put(`appointments/${appointmentId}/respond/${status}`),
        defaultValue: {},
        onError: error => {
            dispatch(errorMessage(`Failed to respond upcoming chat: ${getApiErrorMessageCustomPathEnd(error, "message")}`));
            setIsSubmitting(false)
        },
        onSuccess: (response) => {
            dispatch(message(`${response.message ?? "Chat responded succesfully"}`));
            setIsSubmitting(false)
            getUpcomingAppointments()
        }
    })

    return meeting_status !== "canceled" ? (
        <div className="unconfirmed-appointment-actions">
            {isSubmitting ? (
                <Spinner />
            ) : meeting_status == "Un-confirmed" && createdByPatient ? (
                <>
                    <button
                        className="appointment-action-button cancel-appointment-button"
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                            setIsSubmitting(true)
                            fetchAcceptOrDeclineAppointment("decline")
                        }}
                    >
                        Decline
                    </button>
                    <button
                        className="appointment-action-button reschedule-button"
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                            setIsSubmitting(true)
                            fetchAcceptOrDeclineAppointment("accept")
                        }}
                    >
                        Accept
                    </button>
                </>
            ) : (
                <>
                    <CardCancelAppointmentButton setIsSubmitting={setIsSubmitting} appointmentId={appointmentId} onSuccess={getUpcomingAppointments} patientsToBeNotified={patientsToBeNotified}/>
                    {
                        (appointmentId && can_reschedule && !createdByPatient) || (rescheduleEnabled && meeting_status !== "Un-confirmed" && !createdByPatient) ?
                            <CardRescheduleAppointmentButton appointmentId={appointmentId} timeRange={{Start: start_time, End: end_time}} onSuccess={getUpcomingAppointments} />
                            :
                            null
                    }
                </>

            )}
        </div>
    ) : null;
};

AppointmentActions.propTypes = {
    meeting_status: PropTypes.string,
    getUpcomingAppointments: PropTypes.func,
    createdByPatient: PropTypes.bool,
    appointmentId: PropTypes.number,
    can_reschedule: PropTypes.bool,
    patientsToBeNotified: PropTypes.number,
    start_time: PropTypes.string,
    end_time: PropTypes.string
};

export default AppointmentActions;