import React, { useEffect, useState } from "react";
import { Waiting } from "components/ui";
import moment from "moment-timezone";
import { CreateEditViewTimeSlotModal } from "components/screens/chats/availability-scheduler/create-time-slot-modal";
import { IoArrowDownB, IoArrowUpB } from "react-icons/lib/io";
import PropTypes from "prop-types";

const populateDaysArray = (firstDayOfMonth, daysInMonth) => {
  const initialDaysArray = [];
  for (let i = 0; i < firstDayOfMonth; i++) {
    initialDaysArray.push(null);
  }
  for (let i = 1; i <= daysInMonth; i++) {
    initialDaysArray.push({ number: i, class: "inactive" });
  }
  return initialDaysArray;
};

const CalendarMonth = React.memo(
  ({
    year,
    month,
    availability,
    appointments,
    isLoading,
    editSlot,
    setEditSlot
  }) => {
    const userLoggedIn = JSON.parse(window.localStorage.getItem("evergreen"));
    const today = moment().tz(userLoggedIn.timezone).toDate();
    const [dayOnFocus, setDayOnFocus] = useState();
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];

    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const [daysAppointments, setDaysAppointments] = useState([]);
    const [daysArray, setDaysArray] = useState([]);

    const getStatusClass = meeting_status => {
      return meeting_status.toLowerCase();
    };

    useEffect(() => {
      const initialDaysArray = populateDaysArray(firstDayOfMonth, daysInMonth);
      const updatedDaysArray = initialDaysArray.map(day => {
        if (day) {
          const date = new Date(year, month, day.number);
          const dayOfWeek = date.getDay();
          const availableDay = availability?.find(a => a.day === dayOfWeek);
          return availableDay
            ? {
              ...day,
              class: "active",
              today: today.toDateString() === date.toDateString()
            }
            : {
              ...day,
              today: today.toDateString() === date.toDateString()
            };
        } else {
          return day;
        }
      });
      setDaysArray(updatedDaysArray);
      setDayOnFocus();
    }, [availability, month, year]);

    function parseTimeString(timeStr) {
      const [time, modifier] = timeStr.split(" ");
      let [hours, minutes] = time.split(":").map(Number);

      if (modifier === "PM" && hours < 12) {
        hours += 12;
      } else if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      const now = new Date();
      now.setHours(hours, minutes, 0, 0);

      return now;
    }

    const timeInMinutes = (hours, minutes) => hours * 60 + minutes;

    useEffect(() => {
      if (appointments) {
        const organizedAppointments = Array(daysInMonth)
          .fill(null)
          .map(() => []);
        appointments.map(appointment => {
          const date = new Date(appointment.StartDate);
          const day = date.getDate();
          organizedAppointments[day - 1].push(appointment);
        });

        organizedAppointments.map((dayAppointments, index) => {
          const date = new Date(year, month, index + 1);
          const dayOfWeek = date.getDay();
          const availableDay = availability?.find(a => a.day === dayOfWeek);

          if (availableDay && availableDay.lunch_start) {
            const listOfAppointments = [...dayAppointments];
            const timeLunchStarts = parseTimeString(availableDay.lunch_start);
            const lunchTime = timeInMinutes(
              timeLunchStarts.getHours(),
              timeLunchStarts.getMinutes()
            );
            let lunchIndex = listOfAppointments.length;

            listOfAppointments.some((appointment, i) => {
              const appointmentStart = new Date(appointment.StartDate);
              const appointmentTime = timeInMinutes(
                appointmentStart.getHours(),
                appointmentStart.getMinutes()
              );

              if (lunchTime < appointmentTime) {
                lunchIndex = i;
                return true;
              }
            });

            organizedAppointments[index].splice(lunchIndex, 0, {
              Status: "LunchTime",
              isLunchTime: true,
              start_time: moment(availableDay.lunch_start, "h:mm A").format(
                "MM/DD/YYYY h:mm A"
              )
            });
          }
        });

        setDaysAppointments(organizedAppointments);
      }
    }, [appointments, availability]);

    return (
      <div
        className={`calendar-month ${isLoading ? "loading" : ""}`}
        style={{ position: "relative" }}
      >
        {isLoading && (
          <Waiting
            size="50px"
            style={{
              position: "absolute",
              top: "10%",
              zIndex: "5",
              transform: "translate(-50%,-50%)"
            }}
          />
        )}
        <div className="calendar-month-header">
          {daysOfWeek.map(day => (
            <div key={day} className="calendar-month-header-day">
              {day}
            </div>
          ))}
        </div>
        <div className="calendar-month-body">
          {daysArray.map((day, index) => {
            const dayNumber = day?.number - 1;
            const maxLenght = daysAppointments[dayNumber]?.length;
            return (
              <div
                key={index}
                className={`calendar-month-day ${day?.class} ${dayOnFocus === dayNumber ? "day-on-focus" : ""
                  }`}
              >
                <div
                  className={`calendar-month-day-number ${day?.today ? "selected" : ""
                    }`}
                >
                  {day?.number ? day.number : ""}
                </div>
                <div
                  className={`calendar-month-appointments ${dayOnFocus === dayNumber ? "appointments-on-focus" : ""
                    }`}
                >
                  {daysAppointments[dayNumber]
                    ?.slice(0, dayOnFocus === dayNumber ? maxLenght : 3)
                    .map((appointment, index) => {
                      const startTime = moment(appointment.start_time).format(
                        "hh:mm a"
                      );
                      return (
                        <div
                          key={index}
                          className={`appointment ${getStatusClass(
                            appointment.Status
                          )}`}
                          style={{
                            cursor: `${appointment.IsTimeOff || appointment.isLunchTime
                              ? "default"
                              : "pointer"
                              }`
                          }}
                          onClick={() => {
                            if (
                              !appointment.IsTimeOff &&
                              !appointment.isLunchTime
                            )
                              setEditSlot(appointment);
                          }}
                        >
                          {startTime}
                          {" | "}
                          {appointment.IsTimeOff ? (
                            <span>Time Off</span>
                          ) : (
                            <>
                              {appointment.isLunchTime ? (
                                <span>Lunch Time</span>
                              ) : (
                                <>
                                  {appointment.AssignedPatients?.map((a, i) => {
                                    return (
                                      <span key={i}>
                                        {i > 0 && ", "}
                                        {a.patient_name}
                                      </span>
                                    );
                                  })}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      );
                    })}
                  {maxLenght > 3 &&
                    (dayOnFocus === dayNumber ? (
                      <div
                        className={`show-less-arrow`}
                        onClick={() => {
                          setDayOnFocus();
                        }}
                      >
                        <span>
                          <IoArrowUpB></IoArrowUpB>
                        </span>
                      </div>
                    ) : (
                      <div
                        className={`show-more-arrow`}
                        onClick={() => {
                          setDayOnFocus(dayNumber);
                        }}
                      >
                        <span>
                          <IoArrowDownB></IoArrowDownB> +{maxLenght - 3}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            );
          })}
        </div>
        {editSlot && (
          <CreateEditViewTimeSlotModal
            timeZone={userLoggedIn.timezone}
            data={editSlot}
            isViewing={true}
          />
        )}
      </div>
    );
  }
);
CalendarMonth.propTypes = {
  year: PropTypes.string,
  month: PropTypes.number,
  availability: PropTypes.array,
  appointments: PropTypes.array,
  isLoading: PropTypes.bool,
  editSlot: PropTypes.bool,
  setEditSlot: PropTypes.func
}

export default CalendarMonth;