import React from "react";
import PropTypes from "prop-types";
import useMousePosition from "../availability-scheduler/use-mouse-position";
import { formatTimeFromHour } from "utils/dates";
import {
  managerViewConvertDatesToGridSpan,
  managerViewConvertGridSpanToDates1,
  managerViewGridPosTodate,
  isInvalidSlot,
  isBefore
} from "../availability-scheduler/scheduler-helpers";
import { TimeSlotContext } from "../availability-scheduler/use-availabilityScheduler";
import { Tooltip } from "components/ui/tool-tip/tooltip";

const Times = Array.from(Array(24).keys()).filter(t => t >= 5 && t <= 19);
const CellHeight = 120;
const CellWidth = 50;
const getRow = y => Math.floor(y / CellHeight);
const getCol = x => Math.floor(x / CellWidth);

const CoachTimeline = React.memo(({ children, willEditTimeSlot, timeZone }) => {
  const [mousePosition, schedulerRef] = useMousePosition();

  const {
    setSelected,
    editSlot,
    setEditSlot,
    selected,
    setPendingTimeSlot,
    managerViewCoaches,
    managerAppointments,
    date,
    isBetweenWorkingTime,
    setCoachViewCoachID
  } = React.useContext(TimeSlotContext);

  return (
    <div className="timeline" style={{ position: "relative" }}>
      <div className="empty-corner" />
      <div className="timeline-coaches-header">
        {managerViewCoaches.length > 0 &&
          managerViewCoaches.map(coach => {
            return (
              <div key={coach.id} className="timeline-coach">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setCoachViewCoachID(coach.id)}
                >
                  {!coach.active && (
                    <Tooltip
                      id="coach tooltip"
                      className="single-stat-tooltip"
                      effect="solid"
                      message="Coach is inactive."
                    >
                      <p style={{ color: "red" }}>{coach.name}</p>
                    </Tooltip>
                  )}
                  {coach.active && coach.name}
                </span>
              </div>
            );
          })}
      </div>
      <div className="times">
        {Times.map(t => (
          <div key={t} style={{ gridColumn: t - 4 }}>
            {formatTimeFromHour(t)}
          </div>
        ))}
      </div>
      <div
        ref={schedulerRef}
        className="time-slots-container-timeline"
        onClick={e => {
          e.preventDefault();
        }}
        onMouseLeave={() => {
          setSelected({
            row1: null,
            col: null
          });
        }}
        onMouseDown={() => {
          const row1 = getRow(mousePosition.y);
          const col = getCol(mousePosition.x);
          const coachIsActive = managerViewCoaches[row1].active;

          const selectedStartTime = managerViewGridPosTodate(
            col,
            date,
            timeZone
          );
          if (!isBefore(selectedStartTime) && coachIsActive) {
            setSelected(() => ({
              row1,
              col
            }));
          }
          if (editSlot && !willEditTimeSlot) setEditSlot();
        }}
        onMouseUp={() => {
          if (selected.col !== null && selected.row1 !== null) {
            const col2 = getCol(mousePosition.x + CellWidth);
            const startCol = selected.col <= col2 ? selected.col : col2 - 1;
            const endCol = col2 >= selected.col ? col2 : selected.col + 1;
            const overlappingAppointment = managerAppointments
              .map(pa => {
                const gridSpan = managerViewConvertDatesToGridSpan(
                  managerViewCoaches,
                  pa
                );
                return gridSpan;
              })
              .filter(({ col }) => col === selected.row1)
              .find(slot => {
                const isInvalid = isInvalidSlot(
                  { row1: slot.row1, row2: slot.row2 },
                  { row1: startCol, row2: endCol }
                );
                return isInvalid;
              });

            const newTimeSlot = {
              ...managerViewConvertGridSpanToDates1({
                row1: startCol,
                row2: endCol,
                date
              }),
              coachId: managerViewCoaches[selected.row1].id
            };
            const gridToDate = managerViewGridPosTodate(
              startCol,
              date,
              timeZone
            );

            const isWorkingTime = isBetweenWorkingTime(
              gridToDate,
              managerViewCoaches[selected.row1].id
            );
            if (!overlappingAppointment && isWorkingTime == "work") {
              setPendingTimeSlot(newTimeSlot);
            }
            setSelected({
              row1: null,
              col: null
            });
          }
        }}
      >
        {children}
      </div>
    </div>
  );
});

CoachTimeline.propTypes = {
  children: PropTypes.node,
  willEditTimeSlot: PropTypes.bool,
  timeZone: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default CoachTimeline;
