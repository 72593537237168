import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const TableRow = row => {
  const { StartDate, EndDate, CoachName, CoachId, ChatType, Topic, Status } =
    row;

  return (
    <tr>
      <td>
        {moment(StartDate).format("MM/DD/YYYY")} |{" "}
        {moment(StartDate).format("hh:mm A")} to{" "}
        {moment(EndDate).format("hh:mm A")}
      </td>
      <td>{Topic}</td>
      <td>
        <Link to={`/users/${CoachId}`}>{CoachName}</Link>
      </td>
      <td>{Status}</td>
      <td>{ChatType}</td>
    </tr>
  );
};

export default TableRow;
