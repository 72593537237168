import React from 'react';
import PropTypes from "prop-types";
import { Spinner } from "components/ui";
import { useFetch } from "hooks";
import { put } from "utils/api";
import { path } from "ramda";
import { useDispatch } from 'react-redux';
import * as msg from "actions/message";

export default function FormTableCheckbox({ FormId, IsActive, updateRowArray, CompanyManagesPatientType, setFormToBeAssignedId, otherFormIsLoading, setOtherFormIsLoading, isFormOnDemand }) {

    const dispatch = useDispatch();
    const {
        fetchData: changeActiveStatus,
        isFetching: fetchingActiveChange
    } = useFetch({
        apiFn: ({ idForm, params }) => put(`forms/toggle-active/${idForm}`, params, { "Content-Type": "application/json" }),
        defaultValue: [],
        transformError: path(["response", "body", "status"])
    })

    const handleChangeActive = async (idForm, params) => {
        const response = await changeActiveStatus({ idForm, params });
        if (response) {
            if (response.success) {
                updateRowArray(idForm, params)

                if (params && !isFormOnDemand) {
                    document.getElementById("assignPatientsConfirmationModal").click();
                    setFormToBeAssignedId(idForm)
                }
            }
            else {
                dispatch(msg.errorMessage(response.message))
            }
        } else {
            dispatch(msg.errorMessage("Failed to update forms active status"))
        }
        setOtherFormIsLoading(false)
    }

    const onChange = (value) => {

        if (otherFormIsLoading) return;

        if (value == "true") {
            handleChangeActive(FormId, false)
        } else {
            setOtherFormIsLoading(true)
            handleChangeActive(FormId, true)
        }


    }

    return (
        <div>
            {fetchingActiveChange
                ? (<Spinner />)
                : (<input
                    id={FormId}
                    type="checkbox"
                    disabled={!CompanyManagesPatientType}
                    value={IsActive}
                    checked={IsActive}
                    onChange={(e) => onChange(e.target.value)}
                    style={{ filter: otherFormIsLoading && "grayscale(100%)" }}
                />
                )
            }
        </div>
    )
}

FormTableCheckbox.propTypes = {
    FormId: PropTypes.number.isRequired,
    IsActive: PropTypes.bool.isRequired,
    updateRowArray: PropTypes.func.isRequired,
    CompanyManagesPatientType: PropTypes.bool.isRequired,
    setFormToBeAssignedId: PropTypes.func.isRequired,
    otherFormIsLoading: PropTypes.bool.isRequired,
    setOtherFormIsLoading: PropTypes.func.isRequired,
    isFormOnDemand: PropTypes.bool.isRequired,
};