import React, { useContext, useState } from "react";
import { selectors } from "reducers";
import { TimeSlotContext } from "../../../availability-scheduler/use-availabilityScheduler";
import ScheduledSlot from '../../../availability-scheduler/scheduled-slot';
import { CreateEditViewTimeSlotModal } from '../../../availability-scheduler/create-time-slot-modal';
import TempScheduledSlot from '../../../availability-scheduler/temp-scheduled-slot';
import { convertDatesToGridSpan } from "../../../availability-scheduler/scheduler-helpers";
import { TimeSlots_ByDay } from "../../manager-view-time-slots"
import CalendarByDay from "./CalendarByDay";
import { useSelector } from "react-redux";
import { Spinner } from "components/ui";

const ViewByDay = () => {
  const user = useSelector(selectors.getUser);
  const [willEditTimeSlot] = useState(false)

  const {
    pendingTimeSlot, editSlot, setEditSlot,
    coachViewAppointments, coachViewAvailability,
    gettingCoachViewAppointments, creatingOrEditingTimeSlots
  } = useContext(TimeSlotContext)

  return (
    <div className="calendar-container-by-day">
      <CalendarByDay
        willEditTimeSlot={willEditTimeSlot}
        timeZone={user.timezone}
      >
        <TimeSlots_ByDay timeZone={user.timezone} coachAvailability={coachViewAvailability} />
        {pendingTimeSlot ? (
          <TempScheduledSlot
            {...convertDatesToGridSpan(pendingTimeSlot)}
            setEditSlot={setEditSlot}
            willEditTimeSlot={false}
            timeZone={user.timezone}
          />
        ) : null}
        {coachViewAppointments?.length > 0 && coachViewAppointments
          .map(s => convertDatesToGridSpan(s))
          .map(appointment => <ScheduledSlot key={appointment.AppointmentId} slot={appointment} byDay={true} />)
        }
      </CalendarByDay>
      {editSlot && !pendingTimeSlot && (
        <CreateEditViewTimeSlotModal timeZone={user.timezone} data={editSlot} isViewing={true} /> //isManagerView={false}
      )}
      {
        (gettingCoachViewAppointments || creatingOrEditingTimeSlots) &&
        <div className="calendar-spinner-container">
          <Spinner />
        </div>
      }
    </div>
  )
}

export default ViewByDay